import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const HomePageHeading: React.FC = () => {
  return (
    <Box paddingTop={{ base: '3', sm: '4' }}>
      <Text
        textAlign='center'
        fontWeight='bold'
        fontSize={{ base: 'xl', sm: '3xl' }}
        noOfLines={1}
      >
        X-FACE (最前線運用システム)
      </Text>
    </Box>
  );
};

export default HomePageHeading;
