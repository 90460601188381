import { Button, useToast, ButtonProps } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { useMutation, UseQueryResult } from 'react-query';

import { resolveAlert } from 'src/apis/alerts.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserLocation } from 'src/hoc';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';

interface AlertResolvedButtonProps extends ButtonProps {
  alert: Alert;
  refetchAlert: UseQueryResult['refetch'];
  label?: string;
}
export const AlertResolvedButton: React.FC<AlertResolvedButtonProps> = ({
  alert,
  refetchAlert,
  label = TranslationKeys.markAsResolved,
  ...props
}) => {
  const { translate } = useTranslate();
  const showToast = useToast();
  const { currentUser } = useUserDetails();

  const { locationStream } = useUserLocation();
  const updateSoSMediaMutation = useMutation((alertId: number) =>
    resolveAlert(
      alertId,
      locationStream?.latitude.toString(),
      locationStream?.longitude.toString(),
    ),
  );

  const handleOnBtnClick = () => {
    if (alert && currentUser) {
      updateSoSMediaMutation.mutate(alert.id, {
        onSuccess: () => {
          refetchAlert();
          showToast({
            title: translate(TranslationKeys.alertIsResolved),
            status: 'success',
          });
        },
      });
    }
  };

  if (alert.ResolvedBy || !currentUser) {
    return null;
  }

  return (
    <Button
      py={{ base: '2', sm: '4' }}
      mx='2'
      fontWeight='extrabold'
      fontSize={{ base: 'sm', sm: 'lg' }}
      onClick={() => handleOnBtnClick()}
      rounded='full'
      w='max'
      colorScheme='telegram'
      position='absolute'
      right='2'
      top='2'
      rightIcon={<FaCheck />}
      {...props}
    >
      {translate(label)}
    </Button>
  );
};
