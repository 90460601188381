import * as yup from 'yup';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { stringCapitalize } from 'src/utils';

export const useRegisterSchema = () => {
  const { translate } = useTranslate();

  const registerFormSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterFirstName))
      .transform(stringCapitalize),
    lastName: yup.string().optional().transform(stringCapitalize),
    email: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterEmail))
      .email(translate(TranslationKeys.pleaseEnterValidEmail))
      .lowercase(),
    password: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterPassword)),
    confirmPassword: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterConfirmPassword))
      .oneOf(
        [yup.ref('password'), null],
        translate(TranslationKeys.passwordMismatch),
      ),
  });

  return registerFormSchema;
};
export type RegisterDto = yup.InferType<ReturnType<typeof useRegisterSchema>>;
