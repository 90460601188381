import { VStack } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getUserDetails } from 'src/apis/users.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import { User } from 'src/models';
import { AppNavigationType } from 'src/types/navigation.type';

import { UserBasicDetails } from './basic-details';
import { UserAdditionalInformation } from './user-additional-information';

const USER_DETAILS_QUERY_KEY = 'getUserDetails';

const UserProfilePage = () => {
  const { translate } = useTranslate();
  const { userId }: { userId: string } = useParams();
  const { data: user } = useQuery<User | undefined>(
    [USER_DETAILS_QUERY_KEY, userId],
    () => getUserDetails(userId),
  );

  if (!user) return null;

  return (
    <HeaderWrapper
      pageTitle={translate(TranslationKeys.profile)}
      navigationType={AppNavigationType.back}
      bodyContainerProps={{
        padding: { base: '4', md: '6' },
      }}
    >
      <VStack gridGap='4' h='full' w='full' align='start'>
        <UserBasicDetails user={user} />
        <UserAdditionalInformation user={user} />
      </VStack>
    </HeaderWrapper>
  );
};

export default UserProfilePage;
