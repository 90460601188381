import { Grid, Text } from '@chakra-ui/react';

import { useTranslate } from 'src/hooks/useTranslate';

interface UserPersonalDetailsProps {
  title: string;
  value: string;
}

export const UserDetailsContainer: React.FC<UserPersonalDetailsProps> = ({
  title,
  value,
}) => {
  const { translate } = useTranslate();
  return (
    <Grid
      templateColumns='1fr 0.1fr 1fr'
      alignItems='center'
      borderBottom='1px solid'
      borderColor='gray.200'
      p='2'
    >
      <Text>{translate(title)}</Text>
      <Text>:</Text>
      <Text textTransform='capitalize'>{value || '-'}</Text>
    </Grid>
  );
};
