import { HStack, ListItem, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import UserAvatar from 'src/components/UserAvatar';
import { User } from 'src/models';
import { USER_PROFILE_ROUTE } from 'src/routes/routeList';
import { getUserFullName } from 'src/utils';

export interface AlertItemsProps {
  alertMember: User;
}

export const AlertMemberItem: React.FC<AlertItemsProps> = ({ alertMember }) => {
  const history = useHistory();
  const handleOnClick = () => {
    history.push(
      USER_PROFILE_ROUTE.replace(':userId', alertMember.id.toString()),
    );
  };

  return (
    <ListItem
      as={HStack}
      py='3'
      onClick={handleOnClick}
      cursor='pointer'
      borderBottom='1px solid #E2E8F0'
      gridGap='2'
    >
      <UserAvatar user={alertMember} boxSize='45px' />
      <Text fontWeight='bold' textTransform='capitalize'>
        {getUserFullName(alertMember)}
      </Text>
    </ListItem>
  );
};
