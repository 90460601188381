import _ from 'lodash';

import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { AlertMedia } from 'src/models/Alerts.model';

import { AlertMediaSlider } from './AlertMediaSlider';
import { NothingToShow } from './NothingToShow';

interface AudioAlertMediaProps {
  alertMedia: AlertMedia[] | undefined;
  fromAlertDialog?: boolean;
}

export const AudioAlertMedia: React.FC<AudioAlertMediaProps> = ({
  alertMedia,
  fromAlertDialog = false,
}) => {
  const audioMedia = _.filter(alertMedia, media =>
    media.type.startsWith(ALERT_MEDIA_TYPES.audio),
  );

  if (_.isEmpty(audioMedia)) {
    return fromAlertDialog ? null : <NothingToShow />;
  }

  return <AlertMediaSlider alertMedia={audioMedia} />;
};
