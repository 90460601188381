import {
  ADJUST_VIDEO_QUALITY_THROTTLE_TIME,
  HIGH_VIDEO_QUALITY_CONSTRAINTS,
  LAST_ADJUSTED_TIME,
  LOW_VIDEO_QUALITY_CONSTRAINTS,
  MEDIUM_VIDEO_QUALITY_CONSTRAINTS,
} from 'src/constants/calls.constant';
import { ReportType, VideoQualityLevels } from 'src/types/call.type';

export const useNetworkAndAdjustQuality = () => {
  const adjustVideoQuality = (
    peerConnection: RTCPeerConnection,
    qualityLevel: VideoQualityLevels,
  ) => {
    const videoSender = peerConnection
      .getSenders()
      .find(sender => sender.track?.kind === 'video');

    if (!videoSender || !videoSender.track?.applyConstraints) {
      console.error(
        'Video sender not found or applyConstraints is unsupported in this browser',
      );
      return;
    }

    const constraints = getConstraintsForQuality(qualityLevel);
    videoSender.track
      .applyConstraints(constraints)
      .catch(err =>
        console.error('Error applying video quality constraints:', err),
      );
  };

  const getConstraintsForQuality = (qualityLevel: VideoQualityLevels) => {
    switch (qualityLevel) {
      case VideoQualityLevels.low:
        return LOW_VIDEO_QUALITY_CONSTRAINTS;
      case VideoQualityLevels.medium:
        return MEDIUM_VIDEO_QUALITY_CONSTRAINTS;
      case VideoQualityLevels.high:
        return HIGH_VIDEO_QUALITY_CONSTRAINTS;
      default:
        return {};
    }
  };

  const adjustVideoQualityThrottled = (() => {
    let lastAdjustmentTime = LAST_ADJUSTED_TIME;

    return (
      peerConnection: RTCPeerConnection,
      qualityLevel: VideoQualityLevels,
    ) => {
      const now = Date.now();
      if (now - lastAdjustmentTime > ADJUST_VIDEO_QUALITY_THROTTLE_TIME) {
        lastAdjustmentTime = now;
        adjustVideoQuality(peerConnection, qualityLevel);
      }
    };
  })();

  const analyzeOutboundRtp = (
    report: any,
    peerConnection: RTCPeerConnection,
  ) => {
    if (report.packetsLost > 100) {
      adjustVideoQualityThrottled(peerConnection, VideoQualityLevels.low);
    } else if (report.packetsLost > 50) {
      adjustVideoQualityThrottled(peerConnection, VideoQualityLevels.medium);
    } else {
      adjustVideoQualityThrottled(peerConnection, VideoQualityLevels.high);
    }
  };

  const analyzeInboundRtp = (
    report: any,
    peerConnection: RTCPeerConnection,
  ) => {
    if (report.jitter > 0.03) {
      adjustVideoQualityThrottled(peerConnection, VideoQualityLevels.low);
    } else if (report.jitter > 0.01) {
      adjustVideoQualityThrottled(peerConnection, VideoQualityLevels.medium);
    } else {
      adjustVideoQualityThrottled(peerConnection, VideoQualityLevels.high);
    }
  };

  const monitorNetworkQuality = (peerConnection: RTCPeerConnection) => {
    const intervalId = setInterval(() => {
      if (
        peerConnection.connectionState === 'closed' ||
        peerConnection.iceConnectionState === 'closed'
      ) {
        clearInterval(intervalId);
        return;
      }

      peerConnection.getStats(null).then(stats => {
        stats.forEach(report => {
          if (
            report.type === ReportType.outboundRtp &&
            report.kind === 'video'
          ) {
            analyzeOutboundRtp(report, peerConnection);
          }
          if (
            report.type === ReportType.inboundRtp &&
            report.kind === 'video'
          ) {
            analyzeInboundRtp(report, peerConnection);
          }
        });
      });
    }, 5000);

    return intervalId;
  };

  return { monitorNetworkQuality };
};
