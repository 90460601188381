import _ from 'lodash';
import { AiOutlineSound } from 'react-icons/ai';
import { FiVideo } from 'react-icons/fi';
import { MdOutlinePhotoCamera } from 'react-icons/md';

import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { AlertMedia } from 'src/models/Alerts.model';

export const getAlertSubtitle = (alertMedia: AlertMedia | undefined) => {
  if (alertMedia) {
    if (_.startsWith(alertMedia.type, ALERT_MEDIA_TYPES.audio)) {
      return TranslationKeys.sentAnAudio;
    }
    if (_.startsWith(alertMedia.type, ALERT_MEDIA_TYPES.video)) {
      return TranslationKeys.sentAVideo;
    }
    if (_.startsWith(alertMedia.type, ALERT_MEDIA_TYPES.image)) {
      return TranslationKeys.sentAnImage;
    }
  }
  return null;
};

export const getAlertMediaPreview = (alertMedia: AlertMedia) => {
  if (_.startsWith(alertMedia.type, ALERT_MEDIA_TYPES.audio)) {
    return AiOutlineSound;
  }
  if (_.startsWith(alertMedia.type, ALERT_MEDIA_TYPES.video)) {
    return FiVideo;
  }
  return MdOutlinePhotoCamera;
};
