import { Grid } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect } from 'react';

import Loader from 'src/components/loader/Loader';
import { CUSTOM_SCROLL_BAR_CSS, PAGE_HEIGHT_WITH_NAVBAR } from 'src/constants';
import { useQueryLoaderContext } from 'src/hoc';
import { useHandsFreeOperation } from 'src/hoc/HandsFreeOperationProvider';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { AppNavigationType } from 'src/types/navigation.type';

import ChatMessagesPanel from '../chat/components/ChatMessagesPanel';
import GroupMessageBox from '../chat/components/GroupMessageBox';
import { AsrChatVoiceAnimation } from './components/asr-chat-animation';
import { AsrMapSection } from './components/asr-map';
import { useAsrChat } from './hooks/use-asr-chat';
import { getPageTitleForASRChat } from './utils';

const DEBOUNCE_TIME = 2000;

const ASRChat = () => {
  const { currentUser } = useUserDetails();
  const { setIsLoaderRequired } = useQueryLoaderContext();
  const { setIsListening } = useHandsFreeOperation();

  const {
    transcribeMessage,
    messageThread,
    messages,
    setTextAudio,
    textAudio,
    threadMembersLocation,
    microPhoneRef,
    setMicState,
  } = useAsrChat();

  const debouncedSetIsListening = _.debounce(() => {
    setIsLoaderRequired(false);
    setIsListening(false);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    debouncedSetIsListening();

    return () => {
      setIsListening(true);
      setIsLoaderRequired(true);
    };
  }, []);

  if (!currentUser || !messageThread) {
    return <Loader />;
  }

  return (
    <HeaderWrapper
      pageTitle={getPageTitleForASRChat(messageThread)}
      userSearchAllowed={false}
      navigationType={AppNavigationType.exit}
      shouldHideFooter={true}
    >
      <Grid
        w='full'
        h={PAGE_HEIGHT_WITH_NAVBAR}
        templateRows='2fr 4fr 0.5fr'
        align='start'
        gap='4'
        px='2'
        py='4'
      >
        <AsrMapSection leafletMarkerPositions={threadMembersLocation} />
        <ChatMessagesPanel
          hasRecipientBlocked={false}
          messages={messages}
          MessageComponent={messageThread.isGroup ? GroupMessageBox : undefined}
          textAudio={textAudio}
          setTextAudio={setTextAudio}
          hasAccepted={true}
          css={CUSTOM_SCROLL_BAR_CSS}
          isAsRChat
        />
        <AsrChatVoiceAnimation
          transcribeMessage={transcribeMessage}
          microPhoneRef={microPhoneRef}
          setMicState={setMicState}
        />
      </Grid>
    </HeaderWrapper>
  );
};

export default ASRChat;
