import { createIcon } from '@chakra-ui/react';

export const VideoIcon = createIcon({
  displayName: 'video icon',
  viewBox: '0 0 45 46',
  defaultProps: {
    width: '45',
    height: '46',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  path: [
    <g key='video-icon'>
      <circle cx='22.5889' cy='22.4099' r='22' fill='#26C6DA' />
      <path
        d='M34.2526 16.0006C33.8559 15.7865 33.4079 15.6857 32.9578 15.7092C32.5076 15.7326 32.0725 15.8795 31.7002 16.1337L26.9639 19.3123V16.7849C26.9631 16.089 26.6863 15.4217 26.1942 14.9296C25.702 14.4375 25.0348 14.1607 24.3389 14.1599H12.0889C11.3929 14.1607 10.7257 14.4375 10.2336 14.9296C9.74147 15.4217 9.46465 16.089 9.46387 16.7849V29.0349C9.46465 29.7309 9.74147 30.3981 10.2336 30.8902C10.7257 31.3823 11.3929 31.6591 12.0889 31.6599H24.3389C25.0348 31.6591 25.702 31.3823 26.1942 30.8902C26.6863 30.3981 26.9631 29.7309 26.9639 29.0349V26.5074L31.7011 29.6861C32.0725 29.942 32.5076 30.0899 32.958 30.1134C33.4085 30.137 33.8566 30.0351 34.2526 29.8193C34.7036 29.5688 35.0779 29.2003 35.3353 28.7533C35.5928 28.3062 35.7236 27.7975 35.7139 27.2818V18.5384C35.7237 18.0226 35.5929 17.5138 35.3354 17.0667C35.078 16.6196 34.7037 16.251 34.2526 16.0006ZM25.2139 29.0349C25.2136 29.2669 25.1213 29.4893 24.9573 29.6533C24.7932 29.8174 24.5709 29.9096 24.3389 29.9099H12.0889C11.8569 29.9096 11.6345 29.8174 11.4705 29.6533C11.3064 29.4893 11.2141 29.2669 11.2139 29.0349V16.7849C11.2141 16.5529 11.3064 16.3305 11.4705 16.1665C11.6345 16.0025 11.8569 15.9102 12.0889 15.9099H24.3389C24.5709 15.9102 24.7932 16.0025 24.9573 16.1665C25.1213 16.3305 25.2136 16.5529 25.2139 16.7849V29.0349ZM33.9639 27.2814C33.9733 27.4801 33.9285 27.6777 33.8343 27.8529C33.7401 28.0282 33.5999 28.1744 33.4289 28.2761C33.3126 28.3419 33.1798 28.3728 33.0464 28.3652C32.913 28.3577 32.7845 28.3119 32.6764 28.2334L26.9639 24.4002V21.4196L32.6753 17.5871C32.7842 17.5097 32.9127 17.4647 33.046 17.4571C33.1793 17.4496 33.312 17.4798 33.4289 17.5444C33.5998 17.646 33.7399 17.7922 33.8341 17.9673C33.9284 18.1424 33.9732 18.3398 33.9639 18.5384V27.2814Z'
        fill='#F1F1F1'
      />
    </g>,
  ],
});
