import _ from 'lodash';
import { useQuery } from 'react-query';

import { getAllAlerts } from 'src/apis/alerts.api';
import { Alert } from 'src/models/Alerts.model';
import { AlertView } from 'src/types/alert.types';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kAlertsQueryKey = 'allAlerts';

export const useAlertsQueryClient = () => {
  return useArrayDataQuery<Alert>({
    queryKey: kAlertsQueryKey,
  });
};

const useAlerts = (alertView?: AlertView) => {
  const { data: allAlerts, ...queryMetadata } = useQuery<Alert[]>(
    kAlertsQueryKey,
    getAllAlerts,
  );

  const getFilteredAlerts = () => {
    if (!allAlerts) return [];

    if (alertView === AlertView.ACTIVE) {
      return _.filter(allAlerts, alert => !alert.resolvedAt);
    }

    if (alertView === AlertView.RESOLVED) {
      return _.filter(allAlerts, alert => alert.resolvedAt);
    }

    return allAlerts;
  };

  return { alerts: getFilteredAlerts() as Alert[], allAlerts, queryMetadata };
};

export default useAlerts;
