import { Select } from '@chakra-ui/react';
import _ from 'lodash';

import { ALERT_OPTIONS } from 'src/constants/sos-alert.constants';
import { AlertView } from 'src/types/alert.types';

interface AlertFilterProps {
  setAlertView: (value: AlertView) => void;
}

export const AlertFilter: React.FC<AlertFilterProps> = ({ setAlertView }) => {
  const alertViewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAlertView(e.target.value as AlertView);
  };

  return (
    <Select
      defaultValue={AlertView.ALL}
      w='140px'
      right='0'
      position='absolute'
      onChange={alertViewChange}
      _focus={{}}
    >
      {_.map(ALERT_OPTIONS, ({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
};
