import _ from 'lodash';

import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { AlertMedia } from 'src/models/Alerts.model';

import { AlertMediaSlider } from './AlertMediaSlider';
import { NothingToShow } from './NothingToShow';

interface VideoAlertMediaProps {
  alertMedia: AlertMedia[] | undefined;
  fromAlertDialog?: boolean;
}

export const VideoAlertMedia: React.FC<VideoAlertMediaProps> = ({
  alertMedia,
  fromAlertDialog = false,
}) => {
  const videoMedia = _.filter(alertMedia, media =>
    media.type.startsWith(ALERT_MEDIA_TYPES.video),
  );

  if (_.isEmpty(videoMedia)) {
    return fromAlertDialog ? null : <NothingToShow />;
  }

  return <AlertMediaSlider alertMedia={videoMedia} />;
};
