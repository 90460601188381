import {
  Avatar,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SOSAlertSound from 'src/assets/sos-alert-sound/sos-alert-sound.mp3';
import {
  AlertMediaInformation,
  AudioAlertMedia,
  ImageAlertMedia,
  TextAlertMedia,
  VideoAlertMedia,
} from 'src/components';
import { CallMessageIcon } from 'src/components/CallMessageIcon';
import UserAvatar from 'src/components/UserAvatar';
import { CUSTOM_SCROLL_BAR_CSS } from 'src/constants';
import {
  SOS_ALERT_ANIMATION,
  SOS_ALERT_DATE_FORMAT,
  USER_AVATAR_SIZE,
  USER_AVATAR_SIZE_WITH_FACTOR,
} from 'src/constants/sos-alert.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useExistingAlert } from 'src/hooks/useExistingAlert';
import { useSound } from 'src/hooks/useSound';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import { buildAlertPageRoute } from 'src/routes/alert.route';
import { ALERT_PAGE_ROUTE } from 'src/routes/routeList';
import { AlertMapSection } from './AlertMapSection';

interface SOSAlertDialogProps {
  alertData: Alert;
  isOpen: boolean;
  onClose: () => void;
}

export const SOSAlertDialog: React.FC<SOSAlertDialogProps> = ({
  alertData,
  isOpen,
  onClose,
}) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const [play, { stop }] = useSound(SOSAlertSound, {
    volume: 0.5,
    soundEnabled: true,
    playbackRate: 1,
    loop: true,
    autoplay: false,
  });

  const handleOnCloseAndStopAlertSound = () => {
    stop();
    onClose();
  };

  const closeAlertAndNavigate = () => {
    handleOnCloseAndStopAlertSound();
    history.push(
      buildAlertPageRoute(
        alertData.id,
        alertData.alertMessageThread?.MessageThreadId,
      ),
    );
  };

  const alertUser = alertData?.raisedBy;

  const { liveAlert, liveAlertMedia } = useExistingAlert(alertData.id);

  // play sound on modal load
  useEffect(() => {
    play();
    return () => {
      stop();
    };
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnCloseAndStopAlertSound}
      key={alertData.id}
      scrollBehavior='inside'
      isCentered
    >
      <ModalOverlay />
      <ModalContent w='full' h='full' pt='2rem' m='0'>
        <ModalHeader color='#D50808' fontSize='2xl' textAlign='center'>
          {translate(TranslationKeys.emergencyAlertRaised)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          as={VStack}
          h='max-content'
          spacing='6'
          css={CUSTOM_SCROLL_BAR_CSS}
        >
          <VStack spacing='2' position='relative'>
            <Box
              position='absolute'
              rounded='full'
              border='3px solid'
              borderColor='red'
              animation={SOS_ALERT_ANIMATION}
              onClick={closeAlertAndNavigate}
              cursor='pointer'
            >
              {alertUser ? (
                <UserAvatar user={alertUser} boxSize={USER_AVATAR_SIZE} />
              ) : (
                <Avatar name='Anonymous' boxSize={USER_AVATAR_SIZE} />
              )}
            </Box>
            <VStack w='full' pt={USER_AVATAR_SIZE_WITH_FACTOR}>
              {alertUser ? (
                <Text fontWeight='bold'>
                  {alertUser.firstName} {alertUser.lastName}
                </Text>
              ) : (
                <Text fontWeight='bold'>
                  {translate(TranslationKeys.anonymous)}
                </Text>
              )}
              {alertData && (
                <Text fontSize='md' color='#606060'>
                  {moment(alertData.createdAt).format(SOS_ALERT_DATE_FORMAT)}
                </Text>
              )}
            </VStack>
          </VStack>
          {alertUser && (
            <CallMessageIcon
              userId={alertUser.id}
              onCommunicationStart={handleOnCloseAndStopAlertSound}
            />
          )}
          <TextAlertMedia alertContent={liveAlert?.textContent} />
          <ImageAlertMedia alertMedia={liveAlertMedia} fromAlertDialog />
          <AudioAlertMedia alertMedia={liveAlertMedia} fromAlertDialog />
          <VideoAlertMedia alertMedia={liveAlertMedia} fromAlertDialog />
          <AlertMediaInformation title={translate(TranslationKeys.location)}>
            <AlertMapSection alert={alertData} />
          </AlertMediaInformation>
        </ModalBody>
        <ModalFooter justifyContent='center'>
          <Button
            colorScheme='blue'
            px='20'
            py='6'
            fontWeight='extrabold'
            fontSize='lg'
            onClick={closeAlertAndNavigate}
          >
            {translate(TranslationKeys.moreDetails)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
