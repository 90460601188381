import { Box, Text } from '@chakra-ui/react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { User } from 'src/models';

import { UserDetailsContainer } from './user-detail-container';

interface UserAdditionalInformationProps {
  user: User;
}

export const UserAdditionalInformation: React.FC<
  UserAdditionalInformationProps
> = ({ user }) => {
  return (
    <Box w='full'>
      <Text fontWeight='bold' fontSize='2xl' as='u'>
        Additional Information
      </Text>
      <Box
        border='1px solid'
        borderColor='gray.300'
        rounded='md'
        w='full'
        mt='2'
        mb={{ base: '4', sm: '6' }}
      >
        <UserDetailsContainer
          title={TranslationKeys.phoneNumber}
          value={user.phoneNumber ? `+${user.phoneNumber}` : ''}
        />
        <UserDetailsContainer
          title={TranslationKeys.address}
          value={user.address}
        />
        <UserDetailsContainer
          title={TranslationKeys.postalCode}
          value={user.postalAreaCode?.toString()}
        />
        <UserDetailsContainer title={TranslationKeys.city} value={user.city} />
        <UserDetailsContainer
          title={TranslationKeys.state}
          value={user.state}
        />
        <UserDetailsContainer
          title={TranslationKeys.country}
          value={user.country}
        />
      </Box>
    </Box>
  );
};
