import _ from 'lodash';
import { useEffect } from 'react';
import { TileLayer, useMap } from 'react-leaflet';

import { FlyToUserLocationButton } from 'src/components/FlyToUserLocation/FlyToUserLocation';
import { LeafletMarker, MarkerColors } from 'src/components/Marker';
import { openStreetMapUrl } from 'src/config';
import { Alert } from 'src/models/Alerts.model';

import { LeafMarkerContent } from './leaflet-marker-content';

interface MapContainerBodyProps {
  alert?: Alert;
  allAlerts?: Alert[];
  mapZoom: number;
  mapCenter: L.LatLng;
}

export const MapContainerBody: React.FC<MapContainerBodyProps> = ({
  alert,
  allAlerts,
  mapZoom,
  mapCenter,
}) => {
  const map = useMap();

  useEffect(() => {
    if (mapZoom) {
      map.setView(mapCenter, mapZoom);
    }
  }, [mapZoom]);

  return (
    <>
      <TileLayer url={openStreetMapUrl} />
      {allAlerts &&
        _.map(allAlerts, alert => (
          <LeafletMarker
            key={alert.id}
            markerColor={MarkerColors.red}
            markerContent={<LeafMarkerContent alert={alert} />}
            position={[_.toNumber(alert.latitude), _.toNumber(alert.longitude)]}
          />
        ))}
      {alert && (
        <LeafletMarker
          markerColor={MarkerColors.red}
          position={[_.toNumber(alert.latitude), _.toNumber(alert.longitude)]}
        />
      )}
      <FlyToUserLocationButton />
    </>
  );
};
