import { socket } from '../../config/socketIoInstance';
import { MessageDto } from '../../dto/messages.dto';
import { User } from '../../models';
import { Message, MessageType } from '../../models/Message.model';
import { ValueCallback } from '../../types/common.type';
import {
  kHFOTranscribeMessage,
  kJoinAsrChatThreadEvent,
  kJoinHFOThreadEvent,
  kLeaveAsrChatThreadEvent,
  kLeaveHFOThreadEvent,
  kMultipleStatusUpdateEvent,
  kNewAsrChatEvent,
  kNewMessageEvent,
  kStatusUpdateEvent,
  kStoppedTypingEvent,
  kTextToSpeechEvent,
  kTranscribeMessage,
  kTypingEvent,
} from './events/messages.events';

export interface OnTypingPayload {
  user: Pick<User, 'id' | 'firstName' | 'lastName'>;
}

export type OnTypingCallback = ValueCallback<OnTypingPayload, void>;

interface TypingEventPayload {
  messageThreadId: number;
  user: OnTypingPayload['user'];
}
export type TranscribeMessage = Pick<Message, 'text'> & {
  timestamp: number;
  userId: number;
  isEndOfUtteranceDetected: boolean;
};

export type NewAsrChatMessage = {
  messageThreadId: number;
  userId: number;
  callerName: string;
};

export interface ASRChatAcceptanceStatus {
  userName: string;
  longitude?: string;
  latitude?: string;
  messageThreadId: number;
  isJoined: boolean;
}

export type TextAudioPayload = {
  audio: string;
  messageThreadId: number;
  userId: number[];
  messageId: number;
  messageType: MessageType;
};

export type TextAudio = {
  audio: Buffer;
  messageThreadId: number;
  userId: number[];
  messageId: number;
  messageType: MessageType;
};

type AlertMessageDto = MessageDto & {
  alertId: number;
};

type StatusUpdateEventPayload = Pick<Message, 'id' | 'status'>;
type MultipleStatusUpdateEventPayload = StatusUpdateEventPayload[];

export const typing = (payload: TypingEventPayload) =>
  socket.emit(kTypingEvent, payload);

export const stoppedTyping = (payload: TypingEventPayload) =>
  socket.emit(kStoppedTypingEvent, payload);

export const onSomeoneTyping = (callback: OnTypingCallback) =>
  socket.on(kTypingEvent, callback);

export const unsubscribeSomeoneTyping = (callback: OnTypingCallback) =>
  socket.off(kTypingEvent, callback);

export const onStoppedTyping = (callback: OnTypingCallback) =>
  socket.on(kStoppedTypingEvent, callback);

export const unsubscribeStoppedTyping = (callback: OnTypingCallback) =>
  socket.off(kStoppedTypingEvent, callback);

export const onNewMessage = (callback: ValueCallback<MessageDto>) =>
  socket.on(kNewMessageEvent, callback);

export const onMessageTranscribed = (
  callback: ValueCallback<TranscribeMessage>,
) => socket.on(kTranscribeMessage, callback);

export const onNewAlertMessage = (callback: ValueCallback<AlertMessageDto>) =>
  socket.on(kNewMessageEvent, callback);

export const unsubscribeNewAlertMessage = (
  callback: ValueCallback<AlertMessageDto>,
) => {
  socket.off(kNewMessageEvent, callback);
};

export const unsubscribeOnMessageTranscribed = (
  callback: ValueCallback<TranscribeMessage>,
) => socket.off(kTranscribeMessage, callback);

export const onHFOTranscribed = (callback: ValueCallback<TranscribeMessage>) =>
  socket.on(kHFOTranscribeMessage, callback);

export const unsubscribeHFOTranscribed = (
  callback: ValueCallback<TranscribeMessage>,
) => socket.off(kHFOTranscribeMessage, callback);

export const joinHFOThreadRoom = (userId: number) =>
  socket.emit(kJoinHFOThreadEvent, { userId });

export const leaveHFOThreadRoom = (userId: number) =>
  socket.emit(kLeaveHFOThreadEvent, { userId });

export const onNewAsrChat = (callback: ValueCallback<NewAsrChatMessage>) =>
  socket.on(kNewAsrChatEvent, callback);

export const unsubscribeNewAsrChat = (
  callback: ValueCallback<NewAsrChatMessage>,
) => socket.off(kNewAsrChatEvent, callback);

export const onAsrChatAcceptance = (
  callback: ValueCallback<ASRChatAcceptanceStatus>,
) => socket.on(kNewAsrChatEvent, callback);

export const unsubscribeAsrChatAcceptance = (
  callback: ValueCallback<ASRChatAcceptanceStatus>,
) => socket.off(kNewAsrChatEvent, callback);

export const joinAsrChatThreadRoom = (userId: number) =>
  socket.emit(kJoinAsrChatThreadEvent, { userId });

export const leaveAsrChatThreadRoom = (userId: number) =>
  socket.emit(kLeaveAsrChatThreadEvent, { userId });

export const subscribeOnSpeech = (
  callback: ValueCallback<TextAudioPayload>,
) => {
  socket.on(kTextToSpeechEvent, callback);
};
export const unsubscribeOnSpeech = (
  callback: ValueCallback<TextAudioPayload>,
) => socket.off(kTextToSpeechEvent, callback);

export const unsubscribeNewMessage = (callback: ValueCallback<MessageDto>) => {
  socket.off(kNewMessageEvent, callback);
};

export const onStatusUpdated = (
  callback: ValueCallback<StatusUpdateEventPayload>,
) => socket.on(kStatusUpdateEvent, callback);

export const unsubscribeStatusUpdated = (
  callback: ValueCallback<StatusUpdateEventPayload>,
) => {
  socket.off(kStatusUpdateEvent, callback);
};

export const onMultipleStatusUpdated = (
  callback: ValueCallback<MultipleStatusUpdateEventPayload>,
) => socket.on(kMultipleStatusUpdateEvent, callback);

export const unsubscribeMultipleStatusUpdated = (
  callback: ValueCallback<MultipleStatusUpdateEventPayload>,
) => {
  socket.off(kMultipleStatusUpdateEvent, callback);
};
