import * as _ from 'lodash';
import { DataConnection, MediaConnection } from 'peerjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CAMERA_FACING_MODE } from 'src/constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

import { useUserMedia } from '../context/user-media.context';
import {
  addTrack,
  notifyDisableMic,
  notifyDisableVideo,
  notifyEnableMic,
  notifyEnableVideo,
} from '../utils/data-connection.utils';
import {
  disableAudioTracks,
  disableVideoTracks,
  enableAudioTracks,
  enableVideoTracks,
  hasAudioTracks,
  hasVideoTracks,
} from '../utils/media-stream.utils';
import { useNetworkAndAdjustQuality } from './useNetworkAndAdjustQuality';

export const useStreamControl = (
  calls: MediaConnection[],
  dataConnections: DataConnection[],
  peerStreams: Record<number, MediaStream | null>,
) => {
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  const callsRef = useRef<MediaConnection[]>([]);

  const { localStream } = useUserMedia();
  const { currentUser } = useUserDetails();
  const { monitorNetworkQuality } = useNetworkAndAdjustQuality();

  const audioConstraints: MediaStreamConstraints = {
    audio: true,
  };

  const videoConstraints: MediaStreamConstraints = useMemo(() => {
    return {
      video: {
        facingMode: isFrontCamera
          ? CAMERA_FACING_MODE.user
          : CAMERA_FACING_MODE.environment,
        width: { ideal: 1280 },
        height: { ideal: 720 },
        frameRate: { max: 30 },
      },
    };
  }, [isFrontCamera]);

  const addStreamedTrack = useCallback(
    (track: MediaStreamTrack) => {
      if (localStream && callsRef.current.length > 0) {
        localStream.addTrack(track);
        addTrack(track, localStream, callsRef.current);
      }
    },
    [localStream, callsRef.current.length],
  );

  const removeTracksByType = (
    localStream: MediaStream,
    trackType: 'audio' | 'video',
  ) => {
    localStream
      .getTracks()
      .filter(track => track.kind === trackType)
      .forEach(track => {
        track.stop();
        localStream.removeTrack(track);
      });
  };

  const addTracksByType = (
    sourceStream: MediaStream,
    trackType: 'audio' | 'video',
  ) => {
    sourceStream
      .getTracks()
      .filter(track => track.kind === trackType)
      .forEach(addStreamedTrack);
  };

  const fetchStreamAndMerge = useCallback(
    async (constraints: MediaStreamConstraints, type: 'audio' | 'video') => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        if (!stream || !localStream) {
          console.warn('Stream or localStream is not available.');
          return;
        }

        removeTracksByType(localStream, type);
        addTracksByType(stream, type);
      } catch (error) {
        console.error('Error fetching and merging media stream:', error);
      }
    },
    [localStream],
  );

  const toggleAudio = useCallback(() => {
    if (!currentUser) return;
    setIsAudioMuted(prevAudioMuted => {
      const willAudioBeMuted = !prevAudioMuted;

      if (localStream && hasAudioTracks(localStream)) {
        if (willAudioBeMuted) {
          notifyDisableMic(dataConnections, currentUser.id);
          disableAudioTracks(localStream);
        } else {
          notifyEnableMic(dataConnections, currentUser.id);
          enableAudioTracks(localStream);
        }
      } else {
        fetchStreamAndMerge(audioConstraints, 'audio');
      }

      return willAudioBeMuted;
    });
  }, [localStream, currentUser, dataConnections]);

  const toggleVideo = useCallback(() => {
    if (!currentUser) return;
    setIsVideoMuted(prevVideoMuted => {
      const willVideoBeMuted = !prevVideoMuted;

      if (localStream && hasVideoTracks(localStream)) {
        if (willVideoBeMuted) {
          notifyDisableVideo(dataConnections, currentUser.id);
          disableVideoTracks(localStream);
        } else {
          notifyEnableVideo(dataConnections, currentUser.id);
          enableVideoTracks(localStream);
        }
      } else {
        fetchStreamAndMerge(videoConstraints, 'video');
      }

      return willVideoBeMuted;
    });
  }, [localStream, currentUser, dataConnections, videoConstraints]);

  const switchCamera = useCallback(() => {
    if (localStream) {
      setIsFrontCamera(prevIsFrontCamera => !prevIsFrontCamera);
    }
  }, [localStream]);

  const mutePeerStream = useCallback(
    (callSessionId: keyof Record<number, MediaStream>) => {
      const peerStream = peerStreams[callSessionId];
      if (!peerStream) {
        return;
      }

      _.forEach(peerStream.getAudioTracks(), track => (track.enabled = false));
    },
    [peerStreams],
  );

  const unmutePeerStream = useCallback(
    (callSessionId: keyof Record<number, MediaStream>) => {
      const peerStream = peerStreams[callSessionId];
      if (!peerStream) {
        return;
      }

      _.forEach(peerStream.getAudioTracks(), track => (track.enabled = true));
    },
    [peerStreams],
  );

  useEffect(() => {
    const intervalIds: NodeJS.Timeout[] = [];
    _.forEach(calls, call => {
      const intervalId = monitorNetworkQuality(call.peerConnection);
      intervalIds.push(intervalId);
    });

    return () => {
      intervalIds.forEach(intervalId => clearInterval(intervalId));
    };
  }, [calls]);

  useEffect(() => {
    const initialStatusUpdate = (dataConnections: DataConnection[]) => {
      if (!currentUser) return;

      if (isAudioMuted) {
        notifyDisableMic(dataConnections, currentUser?.id);
      } else {
        notifyEnableMic(dataConnections, currentUser?.id);
      }
      if (!isVideoMuted) {
        notifyEnableVideo(dataConnections, currentUser?.id);
      } else {
        notifyDisableVideo(dataConnections, currentUser?.id);
      }
    };

    initialStatusUpdate(dataConnections);
  }, [currentUser, dataConnections, isAudioMuted, isVideoMuted]);

  useEffect(() => {
    fetchStreamAndMerge(videoConstraints, 'video');
  }, [videoConstraints]);

  useEffect(() => {
    if (calls.length) {
      callsRef.current = calls;
    }
  }, [calls]);

  return {
    isAudioMuted,
    isVideoMuted,
    toggleAudio,
    toggleVideo,
    isFrontCamera,
    switchCamera,
    mutePeerStream,
    unmutePeerStream,
  };
};
