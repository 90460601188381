export const TranslationKeys = {
  login: 'login',
  email: 'email',
  enterPassword: 'enterPassword',
  dontHaveAccount: 'dontHaveAccount',
  createOne: 'createOne',
  register: 'register',
  firstName: 'firstName',
  firstNamePlaceholder: 'firstNamePlaceholder',
  lastName: 'lastName',
  lastNamePlaceholder: 'lastNamePlaceholder',
  emailPlaceholder: 'emailPlaceholder',
  confirmPassword: 'confirmPassword',
  createAccount: 'createAccount',
  alreadyHaveAccount: 'alreadyHaveAccount',
  home: 'home',
  emergencySOS: 'emergencySOS',
  addMoreInfo: 'addMoreInfo',
  photo: 'photo',
  video: 'video',
  voice: 'voice',
  text: 'text',
  takeAPicture: 'takeAPicture',
  previewFile: 'previewFile',
  recordVideo: 'recordVideo',
  videoPreview: 'videoPreview',
  typeSomething: 'typeSomething',
  recordAudio: 'recordAudio',
  previewAudio: 'previewAudio',
  send: 'send',
  addMoreInfoToAlert: 'addMoreInfoToAlert',
  enterMessage: 'enterMessage',
  emergencyAlert: 'emergencyAlert',
  emergencyAlertRaised: 'emergencyAlertRaised',
  moreDetails: 'moreDetails',
  alerts: 'alerts',
  active: 'active',
  resolved: 'resolved',
  alertDetails: 'alertDetails',
  location: 'location',
  image: 'image',
  audio: 'audio',
  markAsResolved: 'markAsResolved',
  alertIsResolved: 'alertIsResolved',
  searchUsers: 'searchUsers',
  startSearchingUsers: 'startSearchingUsers',
  noUsersFound: 'noUsersFound',
  myProfile: 'myProfile',
  enableHfo: 'enableHfo',
  language: 'language',
  english: 'english',
  japanese: 'japanese',
  logout: 'logout',
  logoutFailed: 'logoutFailed',
  pleaseTryAgain: 'pleaseTryAgain',
  switchLanguage: 'switchLanguage',
  languageSwitchSuccess: 'languageSwitchSuccess',
  areYouSureLogout: 'areYouSureLogout',
  cancel: 'cancel',
  confirm: 'confirm',
  profile: 'profile',
  phoneNumber: 'phoneNumber',
  gender: 'gender',
  female: 'female',
  male: 'male',
  addressLineOne: 'addressLineOne',
  addressLineTwo: 'addressLineTwo',
  city: 'city',
  state: 'state',
  postalCode: 'postalCode',
  country: 'country',
  save: 'save',
  reset: 'reset',
  profileUpdateSuccess: 'profileUpdateSuccess',
  avatarUpdateSuccess: 'avatarUpdateSuccess',
  updateAvatar: 'updateAvatar',
  removeAvatar: 'removeAvatar',
  success: 'success',
  avatarRemovedSuccessfully: 'avatarRemovedSuccessfully',
  messages: 'messages',
  personal: 'personal',
  groups: 'groups',
  newPersonalChat: 'newPersonalChat',
  searchForAUser: 'searchForAUser',
  viewProfile: 'viewProfile',
  beginConversation: 'beginConversation',
  search: 'search',
  startSpeaking: 'startSpeaking',
  edit: 'edit',
  en: 'en',
  ja: 'ja',
  connecting: 'connecting',
  callRejected: 'callRejected',
  callMissed: 'callMissed',
  userUnreachable: 'userUnreachable',
  incomingCall: 'incomingCall',
  newGroupChat: 'newGroupChat',
  name: 'name',
  description: 'description',
  next: 'next',
  previous: 'previous',
  createGroup: 'createGroup',
  createdGroup: 'createdGroup',
  addSelectedUser: 'addSelectedUser',
  viewMembers: 'viewMembers',
  editGroupInfo: 'editGroupInfo',
  leaveGroup: 'leaveGroup',
  members: 'members',
  admin: 'admin',
  makeAdmin: 'makeAdmin',
  removeFromGroup: 'removeFromGroup',
  editGroup: 'editGroup',
  groupUpdated: 'groupUpdated',
  groupUpdatedSuccessfully: 'groupUpdatedSuccessfully',
  leaveGroupWarning: 'leaveGroupWarning',
  emptyGroupMessage: 'emptyGroupMessage',
  groupCallWarning: 'groupCallWarning',
  calls: 'calls',
  selectUserToCall: 'selectUserToCall',
  member: 'member',
  joined: 'joined',
  ended: 'ended',
  duration: 'duration',
  feeds: 'feeds',
  noCoverImageFeed: 'noCoverImageFeed',
  noPostToShow: 'noPostToShow',
  feedCreator: 'feedCreator',
  feedMembers: 'feedMembers',
  addFeedMembers: 'addFeedMembers',
  managePermission: 'managePermission',
  manageFeedMemberPermission: 'manageFeedMemberPermission',
  memberPermission: 'memberPermission',
  canPost: 'canPost',
  updatePermission: 'updatePermission',
  removeFromFeed: 'removeFromFeed',
  removeMember: 'removeMember',
  userRemoveFromFeedWarning: 'userRemoveFromFeedWarning',
  review: 'review',
  reviewPost: 'reviewPost',
  postApproval: 'postApproval',
  approvePost: 'approvePost',
  rejectPost: 'rejectPost',
  reviewRemarkOptional: 'reviewRemarkOptional',
  addRemark: 'addRemark',
  submitPostReview: 'submitPostReview',
  postReviewUpdateSuccess: 'postReviewUpdateSuccess',
  awaitingApproval: 'awaitingApproval',
  approved: 'approved',
  rejected: 'rejected',
  writeSomethingHere: 'writeSomethingHere',
  posts: 'posts',
  yourPosts: 'yourPosts',
  like: 'like',
  comment: 'comment',
  addYourComment: 'addYourComment',
  showMoreComments: 'showMoreComments',
  showLessComments: 'showLessComments',
  viewCount: 'viewCount',
  loadNewer: 'loadNewer',
  loadingMore: 'loadingMore',
  editPost: 'editPost',
  deletePost: 'deletePost',
  createPost: 'createPost',
  whatToPost: 'whatToPost',
  document: 'document',
  post: 'post',
  images: 'images',
  documents: 'documents',
  videos: 'videos',
  posting: 'posting',
  newPostAdded: 'newPostAdded',
  updating: 'updating',
  postUpdated: 'postUpdated',
  postUpdateSuccess: 'postUpdateSuccess',
  areYouSureMessage: 'areYouSureMessage',
  postDelete: 'postDelete',
  pageCreator: 'pageCreator',
  pageMembers: 'pageMembers',
  previewChanges: 'previewChanges',
  publishChanges: 'publishChanges',
  continueEditing: 'continueEditing',
  pageContentUpdated: 'pageContentUpdated',
  pageContentUpdatedSuccessfully: 'pageContentUpdatedSuccessfully',
  reviewRequest: 'reviewRequest',
  yourReviewSubmitted: 'yourReviewSubmitted',
  waitForAdminReview: 'waitForAdminReview',
  addPageMembers: 'addPageMembers',
  addSelectedUsers: 'addSelectedUsers',
  removeFromPage: 'removeFromPage',
  removeFromPageWarning: 'removeFromPageWarning',
  pageContent: 'pageContent',
  pageRequest: 'pageRequest',
  discard: 'discard',
  pageContentDiscarded: 'pageContentDiscarded',
  reject: 'reject',
  accepted: 'accepted',
  approveRequest: 'approveRequest',
  rejectRemark: 'rejectRemark',
  reviewRemark: 'reviewRemark',
  letReviewerKnowRemark: 'letReviewerKnowRemark',
  submitPageReview: 'submitPageReview',
  reviewRemarksSendSuccess: 'reviewRemarksSendSuccess',
  remarkSentToUser: 'remarkSentToUser',
  pageContentApproved: 'pageContentApproved',
  insertLink: 'insertLink',
  enterLinkUrl: 'enterLinkUrl',
  insert: 'insert',
  uploadMediaFromDevice: 'uploadMediaFromDevice',
  insertMediaUrl: 'insertMediaUrl',
  insertMedia: 'insertMedia',
  requests: 'requests',
  commandSampleOne: 'commandSampleOne',
  commandSampleTwo: 'commandSampleTwo',
  commandSampleThree: 'commandSampleThree',
  adminLoginSuccess: 'adminLoginSuccess',
  adminLogoutSuccess: 'adminLogoutSuccess',
  loginFailed: 'loginFailed',
  addFeed: 'addFeed',
  numberOfFeeds: 'numberOfFeeds',
  feedName: 'feedName',
  createdBy: 'createdBy',
  public: 'public',
  moderated: 'moderated',
  feedCreatedAt: 'feedCreatedAt',
  actions: 'actions',
  rowsPerPage: 'rowsPerPage',
  pageCount: 'pageCount',
  page: 'page',
  of: 'of',
  createFeed: 'createFeed',
  addCoverImage: 'addCoverImage',
  coverImage: 'coverImage',
  feedUrl: 'feedUrl',
  openToUsers: 'openToUsers',
  allowPostByAnyRegisteredUser: 'allowPostByAnyRegisteredUser',
  feedCreated: 'feedCreated',
  feedAddedSuccessful: 'feedAddedSuccessful',
  error: 'error',
  somethingWentWrong: 'somethingWentWrong',
  pleaseTryAfterSometime: 'pleaseTryAfterSometime',
  feedEdited: 'feedEdited',
  feedEditedSuccessfully: 'feedEditedSuccessfully',
  deleteFeed: 'deleteFeed',
  areYouSureWarningMessage: 'areYouSureWarningMessage',
  delete: 'delete',
  yes: 'yes',
  no: 'no',
  addPost: 'addPost',
  addMember: 'addMember',
  noPostFound: 'noPostFound',
  filters: 'filters',
  searchByUser: 'searchByUser',
  typeUserName: 'typeUserName',
  selectStartDate: 'selectStartDate',
  selectEndDate: 'selectEndDate',
  apply: 'apply',
  totalNumberPost: 'totalNumberPost',
  totalNumberPostView: 'totalNumberPostView',
  update: 'update',
  moderatePosts: 'moderatePosts',
  archive: 'archive',
  moderatedMembers: 'moderatedMembers',
  add: 'add',
  attachments: 'attachments',
  sections: 'sections',
  dashboardSections: 'dashboardSections',
  title: 'title',
  type: 'type',
  position: 'position',
  addSection: 'addSection',
  addNewDashboardSection: 'addNewDashboardSection',
  editDashboardSection: 'editDashboardSection',
  iconLink: 'iconLink',
  textLink: 'textLink',
  imageLink: 'imageLink',
  imageSlider: 'imageSlider',
  provideTitleForSection: 'provideTitleForSection',
  positionMustBeGreater: 'positionMustBeGreater',
  linkText: 'linkText',
  linkUrl: 'linkUrl',
  creator: 'creator',
  addLink: 'addLink',
  addDashboardSectionLink: 'addDashboardSectionLink',
  startAt: 'startAt',
  endsAt: 'endsAt',
  external: 'external',
  editDashboardSectionLink: 'editDashboardSectionLink',
  deleteDashboardSectionLink: 'deleteDashboardSectionLink',
  users: 'users',
  numberOfUsers: 'numberOfUsers',
  avatar: 'avatar',
  userRegisteredOn: 'userRegisteredOn',
  addUser: 'addUser',
  password: 'password',
  deleteUser: 'deleteUser',
  pages: 'pages',
  addPage: 'addPage',
  pageTitle: 'pageTitle',
  pageUrl: 'pageUrl',
  createdOn: 'createdOn',
  addNewPage: 'addNewPage',
  pageCreatedSuccessfully: 'pageCreatedSuccessfully',
  pageInfo: 'pageInfo',
  editPageMember: 'editPageMember',
  userUpdatedSuccessfully: 'userUpdatedSuccessfully',
  userUpdateFailed: 'userUpdateFailed',
  moderateUpdates: 'moderateUpdates',
  moderateMembers: 'moderateMembers',
  typeYourPageContentHere: 'typeYourPageContentHere',
  canUpdate: 'canUpdate',
  canModerateUpdates: 'canModerateUpdates',
  canModerateMembers: 'canModerateMembers',
  alertGroups: 'alertGroups',
  isAnonymous: 'isAnonymous',
  addAlertGroup: 'addAlertGroup',
  nameOfTheGroup: 'nameOfTheGroup',
  anonymous: 'anonymous',
  oneAlertGroupAlreadyExist: 'oneAlertGroupAlreadyExist',
  editTheAlertGroup: 'editTheAlertGroup',
  groupMembers: 'groupMembers',
  addGroupMember: 'addGroupMember',
  deleteGroup: 'deleteGroup',
  exportActivityData: 'exportActivityData',
  downloadData: 'downloadData',
  from: 'from',
  to: 'to',
  dataDownloadSuccess: 'dataDownloadSuccess',
  noDataFound: 'noDataFound',
  emptyContent: 'emptyContent',
  postMustHaveContent: 'postMustHaveContent',
  commentEditedSuccessfully: 'commentEditedSuccessfully',
  commentAddedSuccessfully: 'commentAddedSuccessfully',
  fileTooLarge: 'fileTooLarge',
  fileSizeLimit: 'fileSizeLimit',
  lacsUnitCreated: 'lacsUnitCreated',
  lacsUnitCreatedSuccessfully: 'lacsUnitCreatedSuccessfully',
  lacsGroupCreated: 'lacsGroupCreated',
  lacsGroupCreatedSuccessfully: 'lacsGroupCreatedSuccessfully',
  lacsGroupEdited: 'lacsGroupEdited',
  lacsGroupEditedSuccessfully: 'lacsGroupEditedSuccessfully',
  audioRecording: 'audioRecording',
  pageNotFound: 'pageNotFound',
  goToHome: 'goToHome',
  sosAlertSuccess: 'sosAlertSuccess',
  failToUpdateLang: 'failToUpdateLang',
  serverError: 'serverError',
  pleaseContactAdmin: 'pleaseContactAdmin',
  unknownErrorOccur: 'unknownErrorOccur',
  raiseAnAlert: 'raiseAnAlert',
  draftPageMember: 'draftPageMember',
  by: 'by',
  noPagesToShow: 'noPagesToShow',
  noPagesYet: 'noPagesYet',
  unknownUser: 'unknownUser',
  accept: 'accept',
  block: 'block',
  unblock: 'unblock',
  youAreBlocked: 'youAreBlocked',
  youHaveBlocked: 'youHaveBlocked',
  filesTooLargeNotAttached: 'filesTooLargeNotAttached',
  newMessages: 'newMessages',
  today: 'today',
  yesterday: 'yesterday',
  errorUpdatingGroup: 'errorUpdatingGroup',
  fetchingGroup: 'fetchingGroup',
  previewFiles: 'previewFiles',
  selectMembers: 'selectMembers',
  messageTooLong: 'messageTooLong',
  messageCannotBeMoreThan: 'messageCannotBeMoreThan',
  failedToSendMessage: 'failedToSendMessage',
  isTyping: 'isTyping',
  areTyping: 'areTyping',
  more: 'more',
  typing: 'typing',
  fromTheFeed: 'fromTheFeed',
  areYouSureYouWantToRemove: 'areYouSureYouWantToRemove',
  thisMember: 'thisMember',
  you: 'you',
  permissionsUpdated: 'permissionsUpdated',
  feed: 'feed',
  failedToCreatePost: 'failedToCreatePost',
  comments: 'comments',
  noCommentsToShow: 'noCommentsToShow',
  deleteComment: 'deleteComment',
  updateComment: 'updateComment',
  editComment: 'editComment',
  likes: 'likes',
  nothingMoreToLoad: 'nothingMoreToLoad',
  backgroundUpdating: 'backgroundUpdating',
  letThePostAuthorKnow: 'letThePostAuthorKnow',
  postReview: 'postReview',
  reviewedBy: 'reviewedBy',
  reviewRemarks: 'reviewRemarks',
  reviewedAt: 'reviewedAt',
  approve: 'approve',
  usersWhoLikePost: 'usersWhoLikePost',
  noFeedsYet: 'noFeedsYet',
  loadingMap: 'loadingMap',
  pleaseEnterEmail: 'pleaseEnterEmail',
  pleaseEnterValidEmail: 'pleaseEnterValidEmail',
  pleaseEnterPassword: 'pleaseEnterPassword',
  failedToLoginIn: 'failedToLoginIn',
  unknownErrorOccurTryAgain: 'unknownErrorOccurTryAgain',
  inValidCredentials: 'inValidCredentials',
  addMembersToGroup: 'addMembersToGroup',
  fromTheGroup: 'fromTheGroup',
  failedMakingAdmin: 'failedMakingAdmin',
  failedRemovingAdmin: 'failedRemovingAdmin',
  removeAdmin: 'removeAdmin',
  createGroupChat: 'createGroupChat',
  noGroupChatsYet: 'noGroupChatsYet',
  noPersonalChatsYet: 'noPersonalChatsYet',
  createPersonalChat: 'createPersonalChat',
  errorFetchingGroupInfo: 'errorFetchingGroupInfo',
  groupDescription: 'groupDescription',
  groupInfo: 'groupInfo',
  loadingChats: 'loadingChats',
  sentAFile: 'sentAFile',
  removeVideo: 'removeVideo',
  errorApprovingPageContent: 'errorApprovingPageContent',
  errorDiscardingPageContent: 'errorDiscardingPageContent',
  errorSubmittingYourReview: 'errorSubmittingYourReview',
  pageContentRejected: 'pageContentRejected',
  reviewForYourRequest: 'reviewForYourRequest',
  publishingChanges: 'publishingChanges',
  youHaveSuccessfullyCreatedAccount: 'youHaveSuccessfullyCreatedAccount',
  pleaseLoginToContinue: 'pleaseLoginToContinue',
  failedToRegister: 'failedToRegister',
  userWithSameNameAlreadyExist: 'userWithSameNameAlreadyExist',
  connect: 'connect',
  address: 'address',
  noDataToShow: 'noDataToShow',
  loading: 'loading',
  addImage: 'addImage',
  dashboardSectionLink: 'dashboardSectionLink',
  updateImage: 'updateImage',
  applyFilter: 'applyFilter',
  canModeratePosts: 'canModeratePosts',
  editMember: 'editMember',
  errorRemovingMedia: 'errorRemovingMedia',
  somethingWentWrongMedia: 'somethingWentWrongMedia',
  errorUpdatingPost: 'errorUpdatingPost',
  somethingWentWrongUpdatingPost: 'somethingWentWrongUpdatingPost',
  feedMemberDeleted: 'feedMemberDeleted',
  editFeed: 'editFeed',
  createPage: 'createPage',
  creatingPage: 'creatingPage',
  errorUpdatingPageContent: 'errorUpdatingPageContent',
  errorWhileDiscardingPageContent: 'errorWhileDiscardingPageContent',
  currentPageContent: 'currentPageContent',
  draftPageContent: 'draftPageContent',
  errorSendingReviewRemark: 'errorSendingReviewRemark',
  pageUpdatedSuccessfully: 'pageUpdatedSuccessfully',
  savingChanges: 'savingChanges',
  pageMemberDeleted: 'pageMemberDeleted',
  draftRequest: 'draftRequest',
  userWithSamePhoneNumberAlreadyExists: 'userWithSamePhoneNumberAlreadyExists',
  userCreatedSuccessfully: 'userCreatedSuccessfully',
  userAlreadyExists: 'userAlreadyExists',
  user: 'user',
  oneAnonymousAlertGroupAlreadyExist: 'oneAnonymousAlertGroupAlreadyExist',
  userAlreadyPartOfAlertGroup: 'userAlreadyPartOfAlertGroup',
  callerReceiverCantBeSame: 'callerReceiverCantBeSame',
  youMustBeCallReceiverToAcknowledge: 'youMustBeCallReceiverToAcknowledge',
  youMustBeCallReceiverToReject: 'youMustBeCallReceiverToReject',
  youMustBeCallReceiverToAccept: 'youMustBeCallReceiverToAccept',
  youMustBeCallReceiverToLeave: 'youMustBeCallReceiverToLeave',
  callSessionNotFound: 'callSessionNotFound',
  errorWhileCreatingCall: 'errorWhileCreatingCall',
  callNotFound: 'callNotFound',
  youDontHavePermissionToCreateDashboardSection:
    'youDontHavePermissionToCreateDashboardSection',
  memberAlreadyExistInFeed: 'memberAlreadyExistInFeed',
  youAreNotAllowedToAddMember: 'youAreNotAllowedToAddMember',
  youAreNotAllowedToPost: 'youAreNotAllowedToPost',
  feedAlreadyExist: 'feedAlreadyExist',
  feedNotFound: 'feedNotFound',
  userIsNotGroupAdmin: 'userIsNotGroupAdmin',
  lacsGroupNotCreated: 'lacsGroupNotCreated',
  youAreNotAuthorizedToUpdateLacsGroup: 'youAreNotAuthorizedToUpdateLacsGroup',
  youAreNotAllowedToAddAdmin: 'youAreNotAllowedToAddAdmin',
  youCannotRemoveYourselfAsAdmin: 'youCannotRemoveYourselfAsAdmin',
  youDonotHavePermissionToCreatePage: 'youDonotHavePermissionToCreatePage',
  youDonotHavePermissionToUpdatePage: 'youDonotHavePermissionToUpdatePage',
  unableToIdCommand: 'unableToIdCommand',
  unableToIdUser: 'unableToIdUser',
  unableToExecuteCommand: 'unableToExecuteCommand',
  invalidPostalAreaCode: 'invalidPostalAreaCode',
  invalidUserId: 'invalidUserId',
  enterNameForGroup: 'enterNameForGroup',
  groupNameLongerThan: 'groupNameLongerThan',
  groupNameNotLongerThan: 'groupNameNotLongerThan',
  forgotPassword: 'forgotPassword',
  searchingForUsers: 'searchingForUsers',
  noRequestsPending: 'noRequestsPending',
  pageDraft: 'pageDraft',
  contentStatus: 'contentStatus',
  editContent: 'editContent',
  noContentFound: 'noContentFound',
  draft: 'draft',
  youHaveNoCallsYet: 'youHaveNoCallsYet',
  createACall: 'createACall',
  youDontHaveGroupCalls: 'youDontHaveGroupCalls',
  incomingGroupCall: 'incomingGroupCall',
  reviewBy: 'reviewBy',
  tryToSay: 'tryToSay',
  youAreNotAuthorizedToUpdateLacsUnit: 'youAreNotAuthorizedToUpdateLacsUnit',
  lacsGroups: 'lacsGroups',
  createLacsGroup: 'createLacsGroup',
  organizationName: 'organizationName',
  subdomain: 'subdomain',
  departmentName: 'departmentName',
  contactPersonName: 'contactPersonName',
  contactPersonDesignation: 'contactPersonDesignation',
  shareDataAmongUnits: 'shareDataAmongUnits',
  editLacsGroup: 'editLacsGroup',
  groupAdminList: 'groupAdminList',
  existingAdmins: 'existingAdmins',
  createLacsUnit: 'createLacsUnit',
  unitId: 'unitId',
  unitName: 'unitName',
  IsCloudUnit: 'IsCloudUnit',
  editLacsUnit: 'editLacsUnit',
  cloudUnit: 'cloudUnit',
  NoOfAdmins: 'NoOfAdmins',
  addUnit: 'addUnit',
  adminAddedSuccessfully: 'adminAddedSuccessfully',
  addGroupAdmins: 'addGroupAdmins',
  created: 'created',
  numberOfUnits: 'numberOfUnits',
  totalGroupAdmins: 'totalGroupAdmins',
  viewGroupAdmins: 'viewGroupAdmins',
  ViewAdmins: 'ViewAdmins',
  addGroup: 'addGroup',
  youAreNotAllowedToRemoveAdmin: 'youAreNotAllowedToRemoveAdmin',
  youAreAlreadyAnAdminOfThisUnit: 'youAreAlreadyAnAdminOfThisUnit',
  lacsUnitWithThisIdAlreadyExist: 'lacsUnitWithThisIdAlreadyExist',
  youAreNotAuthorizedToCreateLacsUnit: 'youAreNotAuthorizedToCreateLacsUnit',
  unableToCreateLacsUnitThisTime: 'unableToCreateLacsUnitThisTime',
  lacsUnitNotFound: 'lacsUnitNotFound',

  // Validation messages keys
  pleaseEnterComment: 'pleaseEnterComment',
  pleaseEnterFirstName: 'pleaseEnterFirstName',
  pleaseEnterConfirmPassword: 'pleaseEnterConfirmPassword',
  passwordMismatch: 'passwordMismatch',
  pleaseEnterLastName: 'pleaseEnterLastName',
  pleaseEnterValidPhoneNumber: 'pleaseEnterValidPhoneNumber',
  pleaseSelectGender: 'pleaseSelectGender',
  pleaseEnterValidUrl: 'pleaseEnterValidUrl',
  pleaseEnterUrl: 'pleaseEnterUrl',
  pleaseEnterGroupName: 'pleaseEnterGroupName',
  groupNameMaxLength: 'groupNameMaxLength',
  groupNameMinLength: 'groupNameMinLength',
  pleaseEnterValidPostalCode: 'pleaseEnterValidPostalCode',
  pleaseEnterGroupDescription: 'pleaseEnterGroupDescription',
  pleaseEnterFeedName: 'pleaseEnterFeedName',
  feedIsPublicOrNot: 'feedIsPublicOrNot',
  openToUserOrNot: 'openToUserOrNot',
  anyUserCanPostOrNot: 'anyUserCanPostOrNot',
  feedIsModeratedOrNot: 'feedIsModeratedOrNot',
  pleaseEnterOrganizationName: 'pleaseEnterOrganizationName',
  pleaseEnterDepartmentName: 'pleaseEnterDepartmentName',
  pleaseEnterPhoneNumber: 'pleaseEnterPhoneNumber',
  pleaseEnterCity: 'pleaseEnterCity',
  pleaseEnterState: 'pleaseEnterState',
  pleaseEnterCountry: 'pleaseEnterCountry',
  pleaseEnterLacsUnitId: 'pleaseEnterLacsUnitId',
  pleaseEnterUnitName: 'pleaseEnterUnitName',
  pleaseEnterDescription: 'pleaseEnterDescription',
  pleaseEnterLocationName: 'pleaseEnterLocationName',
  cloudUnitOrNot: 'cloudUnitOrNot',
  pleaseSelectSectionType: 'pleaseSelectSectionType',
  pleaseEnterValidPosition: 'pleaseEnterValidPosition',
  pleaseEnterPosition: 'pleaseEnterPosition',
  pleaseEnterTitle: 'pleaseEnterTitle',
  titleCannotBeLongerThan: 'titleCannotBeLongerThan',
  linkTitleCannotBeLongerThan: 'linkTitleCannotBeLongerThan',
  pleaseEnterLinkTitle: 'pleaseEnterLinkTitle',
  pleaseEnterValidLinkUrl: 'pleaseEnterValidLinkUrl',
  pleaseSpecifyIfLinkIsExternal: 'pleaseSpecifyIfLinkIsExternal',
  pleaseEnterPageTitle: 'pleaseEnterPageTitle',
  pageTitleMustBeAtLeast: 'pageTitleMustBeAtLeast',
  pleaseSelectPrivacySetting: 'pleaseSelectPrivacySetting',
  pleaseSelectModerationSetting: 'pleaseSelectModerationSetting',
  pleaseSelectUserAccessSetting: 'pleaseSelectUserAccessSetting',
  feedNameShouldBeAtLeast4Characters: 'feedNameShouldBeAtLeast4Characters',
  feedNameShouldBeAtMost30Characters: 'feedNameShouldBeAtMost30Characters',
  applicationHeading: 'applicationHeading',
  sentAnImage: 'sentAnImage',
  sentAVideo: 'sentAVideo',
  sentAnAudio: 'sentAnAudio',
  stop: 'stop',
  yourCurrentLocation: 'yourCurrentLocation',
  asr: 'asr',
  messageSendSuccessfully: 'messageSendSuccessfully',
  youAreNotAMemberOfThisGroup: 'youAreNotAMemberOfThisGroup',
  startASRChat: 'startASRChat',
  incomingRadioCommunication: 'incomingRadioCommunication',
  additionalDetails: 'additionalDetails',
  addOneMoreMemberToStartAGroupCall: 'addOneMoreMemberToStartAGroupCall',

  memberAlreadyExistInDefaultGroup: 'memberAlreadyExistInDefaultGroup',
  defaultAlertGroupNotFound: 'defaultAlertGroupNotFound',
  nothingToShow: 'nothingToShow',
  quickUpdate: 'quickUpdate',
  statusReport: 'statusReport',
  statusUpdate: 'statusUpdate',
  links: 'links',
  close: 'close',
  loginToReport: 'loginToReport',
  alertStatusReport: 'alertStatusReport',
  disasterCategory: 'disasterCategory',
  message: 'message',
  chooseReportCategory: 'chooseReportCategory',
  done: 'done',
  reply: 'reply',
  report: 'report',
  accountDeactivatedByAdmin: 'accountDeactivatedByAdmin',
  messageTime: 'messageTime',
  alertNotFound: 'alertNotFound',
  userNotInAlertGroup: 'userNotInAlertGroup',
};
