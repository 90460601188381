import { useQuery } from 'react-query';
import { getIceServersUrl } from 'src/apis/ice-servers-url.api';

export const useIceServersConfig = () => {
  const { data: iceServers } = useQuery('getIceServersUrl', getIceServersUrl);

  return {
    iceServers,
  };
};
