import { keyframes } from '@chakra-ui/react';
import { AlertOptions, AlertView } from 'src/types/alert.types';

const KeyframeCircleBlinkAnimations = keyframes`
from, to {
  border-color: red;
  padding:0px;
}
10% {
  border-color: white;
  padding:1px;
}
20% {
  border-color: red;
  padding:2px;
}
30% {
  border-color: white;
  padding:3px;
}
40% {
  border-color: red;
  padding:4px;
}
50% {
  border-color: white;
  padding:5px;
}
`;

export const SOS_ALERT_ANIMATION = `${KeyframeCircleBlinkAnimations} 1s linear infinite`;

const USER_AVATAR_SIZE_PX = 96;
export const USER_AVATAR_SIZE = `${USER_AVATAR_SIZE_PX}px`;

export const USER_AVATAR_SIZE_WITH_FACTOR = `${USER_AVATAR_SIZE_PX + 14}px`;

export const SOS_ALERT_DATE_FORMAT = 'lll';

export const ALERT_OPTIONS: AlertOptions[] = [
  { value: AlertView.ALL, label: 'All' },
  { value: AlertView.ACTIVE, label: 'Active' },
  { value: AlertView.RESOLVED, label: 'Resolved' },
];

export const ALERT_LIST_DATE_FORMAT = 'MMM D, hh:mm A';
