import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { MessageType } from 'src/models/Message.model';

import { localStorageKeys } from '../constants/local-storage-keys.constant';
import { MessageDto } from '../dto/messages.dto';
import { GroupMessageThread } from '../models/GroupMessageThread.model';
import useGroupChats from './useGroupMessages';
import useNewMessageObserver from './useNewMessageObserver';

type UnreadGroupChatsRecord = Record<GroupMessageThread['id'], number>;

const kUnreadGroupChatsStoreKey = localStorageKeys.unreadGroupChatsStoreKey;

const getUnreadChatsRecord = (): UnreadGroupChatsRecord => {
  const existingUnreadChatsRecord = localStorage.getItem(
    kUnreadGroupChatsStoreKey,
  );

  return existingUnreadChatsRecord ? JSON.parse(existingUnreadChatsRecord) : {};
};

const storeUnreadGroupChatsRecord = (
  unreadGroupChatsRecord: UnreadGroupChatsRecord,
) => {
  localStorage.setItem(
    kUnreadGroupChatsStoreKey,
    JSON.stringify(unreadGroupChatsRecord),
  );
};

// NOTE: We could simply do `!unreadGroupChatsRecord[groupChatId]` but that would mean that if
// a group chat was indeed in record - just with 0 unread messages - we would still say it is not present
// We want to know if the group chat is present in the record at all
const alreadyHasRecord = (
  unreadRecord: UnreadGroupChatsRecord,
  messageThreadId: number,
) => typeof unreadRecord[messageThreadId] === 'number';

const useUnreadGroupChatsRecord = () => {
  const { currentUser } = useUserDetails();
  const [groupChats] = useGroupChats({ enabled: !!currentUser });
  const [unreadGroupChatsRecord, setUnreadGroupChatsRecord] =
    useState<UnreadGroupChatsRecord>(getUnreadChatsRecord());

  // On every change in the unread record, store it in local storage.
  useEffect(() => {
    storeUnreadGroupChatsRecord(unreadGroupChatsRecord);
  }, [unreadGroupChatsRecord]);

  // Observe new chats and update the unread record accordingly.
  useEffect(() => {
    setUnreadGroupChatsRecord(prev => {
      _.forEach(groupChats, ({ id }) => {
        if (!alreadyHasRecord(prev, id)) {
          prev[id] = 0;
        }
      });

      return prev;
    });
  }, [groupChats]);

  // Observe new message and update the unread record accordingly.
  const handleNewMessage = useCallback(
    (message: MessageDto) => {
      setUnreadGroupChatsRecord(prev => {
        // do nothing if the message was sent by the user themselves
        if (currentUser?.id === message.SenderId) {
          return prev;
        }

        if (
          !message.isGroupMessage ||
          message.messageType !== MessageType.normalChat
        ) {
          return prev;
        }

        // message was sent by someone else and it is a group message - update the unread record
        const prevUnread = prev[message.MessageThreadId] || 0;
        return { ...prev, [message.MessageThreadId]: prevUnread + 1 };
      });
    },
    [currentUser?.id],
  );

  useNewMessageObserver({ handleNewMessage });

  const resetUnreadChatsForGroupChatId = (messageThreadId: number) => {
    const newUnreadGroupChatsRecord = {
      ...unreadGroupChatsRecord,
      [messageThreadId]: 0,
    };
    // Explicitly storing here since, by use-case, we reset the unread count when user visits the group chat
    // and when that happens, the useEffect here (the one that monitors state changes and persists them in local storage)
    // is no longer valid since the component using it is no longer mounted
    storeUnreadGroupChatsRecord(newUnreadGroupChatsRecord);
    setUnreadGroupChatsRecord(newUnreadGroupChatsRecord);
  };

  return [unreadGroupChatsRecord, resetUnreadChatsForGroupChatId] as const;
};

export default useUnreadGroupChatsRecord;
