export enum AlertView {
  ALL = 'all',
  ACTIVE = 'active',
  RESOLVED = 'resolved',
}

export interface AlertOptions {
  value: AlertView;
  label: string;
}
