import { Box, Flex, Image, Text } from '@chakra-ui/react';
import L from 'leaflet';
import _ from 'lodash';
import { FC, useRef } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import ReactPlayer from 'react-player';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getFeed } from 'src/apis';
import { FlyToUserLocationButton } from 'src/components/FlyToUserLocation/FlyToUserLocation';
import { LeafletMarker } from 'src/components/Marker';
import { openStreetMapUrl } from 'src/config';
import {
  DEFAULT_CENTER_FOR_POSTS_FEED_MAP,
  DEFAULT_ZOOM_FOR_POSTS_FEED_MAP,
  MAX_BOUNDS,
  MAX_ZOOM,
  MIN_ZOOM,
} from 'src/constants/maps.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useTranslate } from 'src/hooks/useTranslate';
import { FeedModel } from 'src/models';
import { AppNavigationType } from 'src/types/navigation.type';
import { getTimeDuration } from 'src/utils';
import { isImage } from 'src/utils/mime-type.utils';

import useFeedPosts from '../feeds/feed/hooks/useFeedPosts';

export const FeedPostsMap = () => {
  const { translate } = useTranslate();
  const mapRef = useRef();
  const { id: feedId }: { id: string } = useParams();

  const { data: feed } = useQuery<FeedModel>(['getOneFeed', feedId], () =>
    getFeed(feedId),
  );

  const feedLatLng =
    (feed?.latitude &&
      feed?.longitude &&
      new L.LatLng(_.toNumber(feed?.latitude), _.toNumber(feed?.longitude))) ||
    DEFAULT_CENTER_FOR_POSTS_FEED_MAP;

  const [postsList] = useFeedPosts({
    feed,
    feedId: +feedId,
  });

  const postsWithLocation = _.filter(
    postsList,
    post => !_.isNull(post.latitude) && !_.isNull(post.longitude),
  );

  const postWithCoordinates = _.map(postsWithLocation, post => ({
    coordinates: [_.toNumber(post.latitude), _.toNumber(post.longitude)],
    ...post,
  }));

  return (
    <HeaderWrapper
      pageTitle={feed?.name || translate(TranslationKeys.feed)}
      bodyContainerProps={{ bg: 'gray.100' }}
      navigationType={AppNavigationType.back}
    >
      {feed && (
        <MapContainer
          ref={mapRef}
          center={feedLatLng}
          zoom={DEFAULT_ZOOM_FOR_POSTS_FEED_MAP}
          style={{ height: '100%', width: '100%' }}
          maxZoom={MAX_ZOOM}
          minZoom={MIN_ZOOM}
          maxBounds={MAX_BOUNDS}
        >
          <TileLayer url={openStreetMapUrl} />
          {_.map(postWithCoordinates, postCoordinate => {
            const mediaType = _.head(postCoordinate.postMedia)?.type;
            const mediaUrl = _.head(postCoordinate.postMedia)?.mediaUrl;
            return (
              <LeafletMarker
                key={postCoordinate.id}
                markerContent={
                  <ToolTipContent
                    createdAt={postCoordinate.createdAt}
                    mediaType={mediaType}
                    mediaUrl={mediaUrl}
                    textContent={postCoordinate.textContent}
                  />
                }
                position={postCoordinate.coordinates}
              />
            );
          })}
          <FlyToUserLocationButton isFullScreen={true} />
        </MapContainer>
      )}
    </HeaderWrapper>
  );
};

interface ToolTipContentProps {
  mediaType: string | undefined;
  mediaUrl: string | undefined;
  textContent: string;
  createdAt: string;
}

export const ToolTipContent: FC<ToolTipContentProps> = ({
  createdAt,
  mediaType,
  mediaUrl,
  textContent,
}) => {
  const postCreationTime = getTimeDuration(createdAt);
  if (!mediaType) {
    return (
      <Flex px='2' pt='5' pb='2' flexDir='column'>
        <Text fontSize='lg'>{textContent}</Text>
        <Text align='end' fontSize='xs'>
          {postCreationTime}
        </Text>
      </Flex>
    );
  }

  if (isImage(mediaType)) {
    return (
      <Flex px='2' pt='5' pb='2' flexDir='column'>
        <Image src={mediaUrl} />
        <Text fontSize='lg'>{textContent}</Text>
        <Text align='end' fontSize='xs'>
          {postCreationTime}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex px='2' pt='5' pb='2' flexDir='column'>
      <Box maxH='full'>
        <ReactPlayer width='full' light={true} url={mediaUrl} controls />
      </Box>
      <Text fontSize='lg'>{textContent}</Text>
      <Text align='end' fontSize='xs'>
        {postCreationTime}
      </Text>
    </Flex>
  );
};
