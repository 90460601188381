import { Box, Text } from '@chakra-ui/react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

export const AlertResolvedBanner = () => {
  const { translate } = useTranslate();

  return (
    <Box
      width={{ base: '200px', sm: '250px' }}
      height={{ base: '30px', sm: '35px' }}
      bg='red.500'
      color='white'
      position='absolute'
      top={{ base: '6', sm: '6' }}
      right={{ base: '-14', sm: '-20' }}
      zIndex='1'
      display='flex'
      alignItems='center'
      justifyContent='center'
      transform='rotate(45deg)'
    >
      <Text fontSize={{ base: 'md', sm: 'lg' }} fontWeight='semibold'>
        {translate(TranslationKeys.resolved)}
      </Text>
    </Box>
  );
};
