import { Box, List, Text } from '@chakra-ui/react';
import * as _ from 'lodash';

import { useAlertMembers } from '../hooks/useAertMembers';
import { AlertMemberItem } from './AlertMemberItems';

export const AlertMembersPanel = () => {
  const { allAlertMembers } = useAlertMembers();

  if (!allAlertMembers?.length) {
    return (
      <Text textAlign='center' pt='6'>
        No members found
      </Text>
    );
  }

  return (
    <Box position='relative'>
      <List paddingInline='0'>
        {_.map(allAlertMembers, ({ user, id }) => (
          <AlertMemberItem key={id} alertMember={user} />
        ))}
      </List>
    </Box>
  );
};
