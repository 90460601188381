import { axiosInstance } from 'src/config';
import { AlertGroupMembers } from 'src/models/alert-group-member';
import { getRequestData } from './utils/get-request-data.util';

const baseUrl = '/alert-group';

export const getAlertMembers = () => {
  const url = `${baseUrl}/user/members`;
  return getRequestData<AlertGroupMembers[]>(axiosInstance.get(url));
};
