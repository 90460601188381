import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { updateUser } from 'src/apis/users.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';
import { useUserSchema } from 'src/schema';
import { UpdateUserType } from 'src/types/user.type';

interface UpdateDetailsProps {
  setIsEditable: (isEditable: boolean) => void;
}

export const useUpdateDetails = ({ setIsEditable }: UpdateDetailsProps) => {
  const { translate } = useTranslate();
  const { showErrorToast, showToast } = useToast();
  const { currentUser } = useUserDetails();
  const userSchema = useUserSchema();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UpdateUserType>({
    defaultValues: { ...currentUser },
    resolver: yupResolver(userSchema),
  });

  const updateUserMutation = useMutation(updateUser);

  const formSubmitHandle = (userPayload: UpdateUserType) => {
    const phoneNumber = userPayload?.phoneNumber?.split('-')[1];

    updateUserMutation.mutate(
      {
        ...userPayload,
        phoneNumber: phoneNumber ? userPayload.phoneNumber : '',
      },
      {
        onSuccess: () => {
          showToast({
            title: translate(TranslationKeys.profileUpdateSuccess),
            status: 'success',
          });
          setIsEditable(false);
        },
        onError: showErrorToast,
      },
    );
  };

  return {
    register,
    handleSubmit,
    control,
    reset,
    errors,
    formSubmitHandle,
  };
};
