import {
  ComponentWithAs,
  Grid,
  GridItem,
  GridItemProps,
  Icon,
  IconProps,
  Text,
  useDisclosure,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { FC, useState } from 'react';

import { CameraIcon } from 'src/assets';
import { TextIcon } from 'src/assets/icons/Text-icon';
import { VideoIcon } from 'src/assets/icons/video-icon';
import { VoiceIcon } from 'src/assets/icons/voice-icon';
import { AudioDialog, VideoDialog } from 'src/components';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import CameraDialog from 'src/pages/chat/components/CameraDialog';

import { AlertTextDialog } from './AlertTextDialog';
import { SOSMediaPreviewDialog } from './SosMediaPreviewDialog';

interface TextMediaIconProps {
  alertId: Alert['id'];
  sendAlertMessage: (messageContent: string) => void;
}

export const AlertMediaIcons: FC<TextMediaIconProps> = ({
  alertId,
  sendAlertMessage,
}) => {
  const { translate } = useTranslate();
  const [mediaToPreview, setMediaToPreview] = useState<
    Blob[] | File[] | FileList
  >([]);

  const photoModalDisclosure = useDisclosure();
  const textModalDisclosure = useDisclosure();
  const audioModalDisclosure = useDisclosure();
  const previewModalDisclosure = useDisclosure();
  const videoModalDisclosure = useDisclosure();

  const handleOnImageCapture: BlobCallback = blob => {
    if (blob) {
      setMediaToPreview([blob]);
      photoModalDisclosure.onClose();
      previewModalDisclosure.onOpen();
    }
  };

  const onPhotoIconClick = () => {
    photoModalDisclosure.onOpen();
  };

  const onAudioIconClick = () => {
    audioModalDisclosure.onOpen();
  };

  const onVideoIconClick = () => {
    videoModalDisclosure.onOpen();
  };

  const closeMediaPreview = () => {
    setMediaToPreview([]);
    previewModalDisclosure.onClose();
  };

  const onTextIconClick = () => {
    textModalDisclosure.onOpen();
  };

  return (
    <HStack>
      <Grid templateColumns='repeat(4, 1fr)' gap='4'>
        <InformationIcons
          onClick={onPhotoIconClick}
          icon={CameraIcon}
          title={translate(TranslationKeys.photo)}
          bg='#FFE6E2'
        />
        <InformationIcons
          onClick={onVideoIconClick}
          icon={VideoIcon}
          title={translate(TranslationKeys.video)}
          bg='#E3F8FA'
        />
        <InformationIcons
          onClick={onAudioIconClick}
          icon={VoiceIcon}
          title={translate(TranslationKeys.voice)}
          bg='#F5E6FE'
        />
        <InformationIcons
          onClick={onTextIconClick}
          icon={TextIcon}
          title={translate(TranslationKeys.text)}
          bg='#FFF0E5'
        />
      </Grid>

      {previewModalDisclosure.isOpen && (
        <SOSMediaPreviewDialog
          media={mediaToPreview}
          alertId={alertId}
          {...previewModalDisclosure}
          onClose={closeMediaPreview}
        />
      )}
      {photoModalDisclosure.isOpen && (
        <CameraDialog
          {...photoModalDisclosure}
          onImageCapture={handleOnImageCapture}
        />
      )}
      {textModalDisclosure.isOpen && (
        <AlertTextDialog
          sendAlertMessage={sendAlertMessage}
          {...textModalDisclosure}
        />
      )}
      {audioModalDisclosure.isOpen && (
        <AudioDialog {...audioModalDisclosure} alertId={alertId} />
      )}
      {videoModalDisclosure.isOpen && (
        <VideoDialog {...videoModalDisclosure} alertId={alertId} />
      )}
    </HStack>
  );
};

interface InformationIconsProps {
  onClick: () => void;
  icon: ComponentWithAs<'svg', IconProps>;
  title: string;
  bg: GridItemProps['bg'];
}

const InformationIcons: React.FC<InformationIconsProps> = ({
  onClick,
  icon,
  title,
  bg,
}) => {
  return (
    <GridItem bg={bg} borderRadius='10' onClick={onClick} cursor='pointer'>
      <VStack spacing='1' py='2' px='3'>
        <Icon as={icon} boxSize='5' />
        <Text fontWeight='semibold' fontSize='xs'>
          {title}
        </Text>
      </VStack>
    </GridItem>
  );
};
