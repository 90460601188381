import { Link } from '@chakra-ui/react';
import { useInternetConnection } from 'src/hooks/useInternetConnection';

interface ExternalNavigationLinkProps {
  linkUrl: string;
}

export const ExternalNavigationLink: React.FC<ExternalNavigationLinkProps> = ({
  linkUrl,
  children,
}) => {
  const { isInternetAvailable } = useInternetConnection();
  const isDisabled = !isInternetAvailable;

  return (
    <Link
      href={isDisabled ? '#' : linkUrl}
      title={isDisabled ? 'No internet connection' : linkUrl}
      pointerEvents={isDisabled ? 'none' : 'auto'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      isExternal
    >
      {children}
    </Link>
  );
};
