import {
  HStack,
  IconButton,
  Image,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useState } from 'react';
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from 'react-icons/io';
import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { AlertMedia } from 'src/models/Alerts.model';

interface AlertMediaSliderProps {
  alertMedia: AlertMedia[];
}

export const AlertMediaSlider: React.FC<AlertMediaSliderProps> = ({
  alertMedia,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const mediaComponent = (media: AlertMedia) => {
    if (media.type.startsWith(ALERT_MEDIA_TYPES.image)) {
      return <Image src={media.mediaUrl} h='25vh' />;
    }

    if (media.type.startsWith(ALERT_MEDIA_TYPES.video)) {
      return (
        <video style={{ height: '25vh', width: '100%' }} controls playsInline>
          <source src={`${media.mediaUrl}#t=0.001`} type={media.type} />
        </video>
      );
    }

    if (media.type.startsWith(ALERT_MEDIA_TYPES.audio)) {
      return (
        <audio style={{ width: '100%' }} controls playsInline>
          <source src={media.mediaUrl} type={media.type} />
        </audio>
      );
    }

    return null;
  };

  return (
    <HStack h='full' w='full'>
      <PreviousButton
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <Tabs
        h='full'
        w='full'
        variant='unstyled'
        position='relative'
        onChange={setSelectedTab}
        index={selectedTab}
      >
        <TabPanels h='full'>
          {_.map(alertMedia, media => {
            return (
              <TabPanel
                h='full'
                p='0'
                as={VStack}
                justify='center'
                key={media.id}
              >
                {mediaComponent(media)}
                <Text>{media.caption}</Text>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
      <NextButton
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        limit={alertMedia.length}
      />
    </HStack>
  );
};

interface ButtonProps {
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

const PreviousButton: React.FC<ButtonProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <IconButton
      _focus={{}}
      _hover={{}}
      variant='ghost'
      aria-label='Previous'
      onClick={() => {
        setSelectedTab(selectedTab - 1);
      }}
      disabled={selectedTab === 0}
      icon={<IoMdArrowDropleftCircle fill='black' size='30' />}
      color='black'
    />
  );
};

interface NextButtonProps extends ButtonProps {
  limit: number;
}

const NextButton: React.FC<NextButtonProps> = ({
  selectedTab,
  setSelectedTab,
  limit,
}) => {
  return (
    <IconButton
      _focus={{}}
      _hover={{}}
      variant='ghost'
      aria-label='Next'
      onClick={() => {
        setSelectedTab(selectedTab + 1);
      }}
      disabled={selectedTab === limit - 1}
      icon={<IoMdArrowDroprightCircle fill='black' size='30' />}
      color='black'
    />
  );
};
