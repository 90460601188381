import Peer, { DataConnection, MediaConnection } from 'peerjs';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';
import { leaveMultipleCallSessions } from 'src/apis/call-sessions.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

import usePeerDataConnection from './usePeerDataConnection';
import { leaveCalls } from '../utils/call-session.utils';
import { useUserMedia } from '../context/user-media.context';
import { useIceServersConfig } from './useIceServers';

const usePeerCall = () => {
  const [dataConnections, setDataConnections] = useState<DataConnection[]>([]);
  const [calls, setCalls] = useState<MediaConnection[]>([]);
  const [peerStreams, setPeerStreams] = useState<
    Record<number, MediaStream | null>
  >({});
  const [peerInstance, setPeerInstance] = useState<Peer>();
  const [isPeerConnected, setIsPeerConnected] = useState(false);
  const [isIncomingCallHandled, setIsIncomingCallHandled] = useState(false);

  const {
    handleDataChannelMessage,
    peerAudioStatus,
    peerVideoStatus,
    cleanupPeerStatus,
  } = usePeerDataConnection();
  const { iceServers } = useIceServersConfig();
  const { localStream } = useUserMedia();
  const { currentUser } = useUserDetails();
  const { callSessions } = useCallSessionsContext();
  const leaveAllCallSessions = useMutation(leaveMultipleCallSessions);

  const initializePeerEvents = (peer: Peer, localStream: MediaStream) => {
    peer.on('open', (id: string) => {
      setIsPeerConnected(true);
    });

    peer.on('call', call => handleIncomingCall(call, localStream, peer));
    peer.on('connection', handleIncomingConnection);
    peer.on('error', handlePeerError);
    peer.on('close', handlePeerClose);
    peer.on('disconnected', handlePeerClose);

    setIsIncomingCallHandled(true);
  };

  const handleIncomingCall = useCallback(
    (call: MediaConnection, localStream: MediaStream, peer: Peer) => {
      if (calls.find(c => c.peer === call.peer)) {
        console.log('Call already answered with peer:', call.peer);
        call.close();
        return;
      }

      setCalls(prevCalls => [...prevCalls, call]);

      call.answer(localStream);

      call.on('stream', remoteStream => {
        if (peerStreams[parseInt(call.peer)]) {
          return;
        }
        setPeerStreams(prevStreams => ({
          ...prevStreams,
          [parseInt(call.peer)]: remoteStream,
        }));
      });

      call.on('error', error => {
        console.error('Call error:', error);
      });

      const dataConnection = peer.connect(call.peer, {
        serialization: 'json',
      });

      dataConnection.on('open', () => {
        setDataConnections(prev => [...prev, dataConnection]);
      });

      handleDataChannelMessage(dataConnection);
    },
    [calls, peerStreams],
  );

  const handleIncomingConnection = (connection: DataConnection) => {
    setDataConnections(prevConnections => [...prevConnections, connection]);
    handleDataChannelMessage(connection);
  };

  const handlePeerError = useCallback(
    (error: any) => {
      console.error('Peer error:', error);
      setIsPeerConnected(false);
      leaveCalls(callSessions, leaveAllCallSessions);
    },
    [callSessions, leaveAllCallSessions],
  );

  const handlePeerClose = useCallback(() => {
    setIsPeerConnected(false);
    leaveCalls(callSessions, leaveAllCallSessions);
  }, [callSessions, leaveAllCallSessions]);

  const createPeer = (selfPeerId: string, iceServers: RTCIceServer[]) => {
    if (peerInstance) {
      peerInstance.destroy();
    }

    const newPeer = new Peer(selfPeerId, {
      host: window.location.hostname,
      port: +process.env.REACT_APP_PEERJS_PORT!,
      path: process.env.REACT_APP_PEERJS_PATH,
      config: {
        iceServers,
      },
    });
    setPeerInstance(newPeer);
  };

  const handlePeer = useCallback((localStream: MediaStream, peer: Peer) => {
    initializePeerEvents(peer, localStream);
  }, []);

  const callPeer = useCallback(
    (otherPeerId: string, localStream: MediaStream, peer: Peer) => {
      if (!peer || !isPeerConnected) {
        console.log('Peer not instantiated or connected');
        return;
      }

      if (peerStreams[parseInt(otherPeerId)]) {
        console.log('Stream already exists for peer: ', otherPeerId);
        return;
      }

      const call = peer.call(otherPeerId, localStream);

      // first check if call already exists
      if (calls.find(c => c.peer === otherPeerId)) {
        console.log('Call already exists with peer:', otherPeerId);
        call.close();
        return;
      }

      setCalls(prevCalls => [...prevCalls, call]);

      call.on('stream', remoteStream => {
        if (peerStreams[parseInt(otherPeerId)]) {
          return;
        }
        setPeerStreams(prevStreams => ({
          ...prevStreams,
          [parseInt(otherPeerId)]: remoteStream,
        }));
      });

      call.on('error', error => {
        console.error('Call error:', error);
      });

      const dataConnection = peer.connect(otherPeerId, {
        serialization: 'json',
      });

      dataConnection.on('open', () => {
        setDataConnections(prev => [...prev, dataConnection]);
      });

      handleDataChannelMessage(dataConnection);
    },
    [calls, peerStreams, isPeerConnected],
  );

  const cleanupRemovedPeerCall = (peerId: number) => {
    setCalls(prevCalls => {
      const newCalls = prevCalls.filter(call => {
        if (parseInt(call.peer) === peerId) {
          call.close();
          return false;
        }
        return true;
      });
      return newCalls;
    });

    setPeerStreams(prevStreams => {
      const newPeerStreams = { ...prevStreams };
      delete newPeerStreams[peerId];
      return newPeerStreams;
    });

    setDataConnections(prevConnections => {
      const newConnections = prevConnections.filter(connection => {
        if (parseInt(connection.peer) === peerId) {
          connection.close();
          return false;
        }
        return true;
      });
      return newConnections;
    });

    cleanupPeerStatus(peerId);
  };

  const cleanupPeer = () => {
    dataConnections.forEach(connection => {
      if (connection) {
        connection.close();
      }
    });

    calls.forEach(call => {
      if (call) {
        call.close();
      }
    });

    // Clear peer streams
    Object.values(peerStreams).forEach(stream => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    });

    setCalls([]);
    setPeerStreams({});
    setDataConnections([]);

    peerInstance?.destroy();
    setPeerInstance(undefined);

    // Reset connection status
    setIsPeerConnected(false);
    setIsIncomingCallHandled(false);
  };

  useEffect(() => {
    if (!peerInstance && currentUser && localStream && iceServers) {
      createPeer(currentUser.id.toString(), iceServers);
    }

    return () => {
      if (peerInstance) {
        peerInstance.destroy();
      }
    };
  }, [currentUser, localStream, peerInstance, iceServers]);

  useEffect(() => {
    if (!isIncomingCallHandled && peerInstance && localStream) {
      handlePeer(localStream, peerInstance);
    }
  }, [localStream, peerInstance, isIncomingCallHandled]);

  return {
    callPeer,
    dataConnections,
    calls,
    peerStreams,
    peerAudioStatus,
    peerVideoStatus,
    cleanupRemovedPeerCall,
    cleanupPeer,
    peer: peerInstance,
  };
};

export default usePeerCall;
