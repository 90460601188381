import { DataConnection } from 'peerjs';
import { useState } from 'react';

import { DataChannelsEventsMessage } from '../constants/data-channels.constants';

const usePeerDataConnection = () => {
  const [peerAudioStatus, setPeerAudioStatus] = useState<
    Record<number, boolean>
  >({});
  const [peerVideoStatus, setPeerVideoStatus] = useState<
    Record<number, boolean>
  >({});

  const handleDataChannelMessage = (dataChannel: DataConnection) => {
    dataChannel.on('data', dataChannelMessageForVideoAndAudioStatus => {
      const { message, senderId } = dataChannelMessageForVideoAndAudioStatus;

      if (message === DataChannelsEventsMessage.enableVideo) {
        setPeerVideoStatus(prev => ({
          ...prev,
          [senderId]: true,
        }));
      } else if (message === DataChannelsEventsMessage.disableVideo) {
        setPeerVideoStatus(prev => ({
          ...prev,
          [senderId]: false,
        }));
      }
      if (message === DataChannelsEventsMessage.enableMic) {
        setPeerAudioStatus(prev => ({
          ...prev,
          [senderId]: true,
        }));
      } else if (message === DataChannelsEventsMessage.disableMic) {
        setPeerAudioStatus(prev => ({
          ...prev,
          [senderId]: false,
        }));
      }
    });
  };

  const cleanupPeerStatus = (peerId: number) => {
    setPeerAudioStatus(prev => {
      const newPeerAudioStatus = { ...prev };
      delete newPeerAudioStatus[peerId];
      return newPeerAudioStatus;
    });
    setPeerVideoStatus(prev => {
      const newPeerVideoStatus = { ...prev };
      delete newPeerVideoStatus[peerId];
      return newPeerVideoStatus;
    });
  };

  return {
    handleDataChannelMessage,
    peerAudioStatus,
    peerVideoStatus,
    cleanupPeerStatus,
  };
};

export default usePeerDataConnection;
