import { useRef, useState, useEffect } from 'react';
import { CAMERA_FACING_MODE } from 'src/constants';

export const useCapturePhoto = (onImageCapture: BlobCallback) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [isFrontCamera, setIsFrontCamera] = useState<boolean>(false);
  const [videoStream, setVideoStream] = useState<MediaStream>();

  const releaseCamera = () => {
    videoStream?.getTracks().forEach(track => track.stop());
  };

  const takePhoto = () => {
    if (!videoRef.current || !canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const video = videoRef.current;

    const videoHeight = video.clientHeight;
    const videoWidth = video.clientWidth;

    canvas.height = videoHeight;
    canvas.width = videoWidth;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
      canvas.toBlob(blob => {
        releaseCamera();
        onImageCapture(blob);
      });
    }
  };

  const switchCamera = () => {
    setIsFrontCamera(prev => !prev);
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video && videoStream) {
      video.srcObject = videoStream;
      video.play();
    }
  }, [videoStream]);

  useEffect(() => {
    const getUserVideo = async () => {
      try {
        releaseCamera();
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: isFrontCamera
              ? CAMERA_FACING_MODE.user
              : CAMERA_FACING_MODE.environment,
          },
        });
        setVideoStream(stream);
      } catch (error) {
        console.error('Error fetching user media', error);
      }
    };

    getUserVideo();
  }, [isFrontCamera]);

  return {
    releaseCamera,
    takePhoto,
    canvasRef,
    videoRef,
    setIsFrontCamera,
    switchCamera,
  };
};
