import { DataConnection, MediaConnection } from 'peerjs';
import * as _ from 'lodash';

import { DataChannelsEventsMessage } from '../constants/data-channels.constants';

export const notifyEnableVideo = (
  dataConnections: DataConnection[],
  userId: number,
) => {
  _.forEach(dataConnections, channel => {
    channel.send({
      message: DataChannelsEventsMessage.enableVideo,
      senderId: userId,
    });
  });
};

export const notifyDisableVideo = (
  dataConnections: DataConnection[],
  userId: number,
) => {
  _.forEach(dataConnections, channel => {
    channel.send({
      message: DataChannelsEventsMessage.disableVideo,
      senderId: userId,
    });
  });
};

export const notifyEnableMic = (
  dataConnections: DataConnection[],
  userId: number,
) => {
  _.forEach(dataConnections, channel => {
    channel.send({
      message: DataChannelsEventsMessage.enableMic,
      senderId: userId,
    });
  });
};

export const notifyDisableMic = (
  dataConnections: DataConnection[],
  userId: number,
) => {
  _.forEach(dataConnections, channel => {
    channel.send({
      message: DataChannelsEventsMessage.disableMic,
      senderId: userId,
    });
  });
};

export const addTrack = (
  track: MediaStreamTrack,
  stream: MediaStream,
  calls: MediaConnection[],
) => {
  _.forEach(calls, call => {
    const sender = _.find(
      call.peerConnection.getSenders(),
      sender => sender.track?.kind === track.kind,
    );

    if (sender && stream.getTracks().length) {
      sender.replaceTrack(track);
    }
  });
};
