import { Text, VStack } from '@chakra-ui/react';
import moment from 'moment';

import { SOS_ALERT_DATE_FORMAT } from 'src/constants/sos-alert.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import { AlertMediaIcons } from 'src/pages/home/components/AlertMediaIcons';

import { CallMessageIcon } from '../CallMessageIcon';

interface AlertInformationProps {
  alert: Alert;
  sendAlertMessage: (messageContent: string, category?: string) => void;
}

export const AlertInformation: React.FC<AlertInformationProps> = ({
  alert,
  sendAlertMessage,
}) => {
  const { resolvedBy, raisedBy: alertUser } = alert;
  const { currentUser } = useUserDetails();
  const alertDate = moment(alert.createdAt).format(SOS_ALERT_DATE_FORMAT);
  const { translate } = useTranslate();

  return (
    <VStack align='start' lineHeight='none' spacing='3' px='6' w='full'>
      {alertUser ? (
        <Text fontWeight='bold' fontSize={{ base: 'xl', sm: '3xl' }}>
          {alertUser.firstName} {alertUser.lastName}
        </Text>
      ) : (
        <Text fontWeight='bold' fontSize={{ base: 'xl', sm: '3xl' }}>
          {translate(TranslationKeys.anonymous)}
        </Text>
      )}
      <Text fontSize='md' color='#606060'>
        {alertDate}
      </Text>
      {alertUser && alertUser.id !== currentUser?.id ? (
        <CallMessageIcon userId={alertUser.id} w='max' />
      ) : (
        !resolvedBy && (
          <AlertMediaIcons
            alertId={alert.id}
            sendAlertMessage={sendAlertMessage}
          />
        )
      )}
    </VStack>
  );
};
