import { MutableRefObject } from 'react';

import {
  getDateDiffSecondsFromNow,
  secondsToDuration,
} from 'src/pages/peer-call/components/header/components/call-duration/utils/call-duration.utils';
import { CheckElementPositionProps } from 'src/types/call.type';
import { ColorShaderMap } from 'src/utils';

export const getDuration = (joinedAt: Date, disconnectedAt: Date) => {
  if (joinedAt && disconnectedAt) {
    const userJoinedAt = getDateDiffSecondsFromNow(new Date(joinedAt));
    const userDisconnectedAt = getDateDiffSecondsFromNow(
      new Date(disconnectedAt),
    );
    const durationDifference = userJoinedAt - userDisconnectedAt;
    return secondsToDuration(durationDifference);
  }
};

export const getColorForCallIcon = (joinedAt: Date | null) => {
  if (joinedAt) {
    return ColorShaderMap.green[500];
  } else if (!joinedAt) {
    return ColorShaderMap.red[500];
  } else {
    return ColorShaderMap.gray[500];
  }
};

export const getCallsTabIndexNumber = (key: string) => {
  return localStorage.getItem(key);
};

export const setCallsTabIndexNumber = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getElementCoordinates = (
  elementRef: MutableRefObject<HTMLDivElement | null>,
) => {
  if (elementRef.current) {
    const rect = elementRef.current.getBoundingClientRect();
    return rect.bottom;
  }
  return null;
};

export const checkElementPosition = ({
  hasMore,
  isFetched,
  isFetchingData,
  isLoading,
  observerRef,
  pageRef,
  setPageNumber,
}: CheckElementPositionProps) => {
  if (!pageRef.current || !observerRef.current) return;

  const pageBottomCoordinates = getElementCoordinates(pageRef);
  const observerBottomCoordinates = getElementCoordinates(observerRef);

  if (
    hasMore &&
    pageBottomCoordinates &&
    observerBottomCoordinates &&
    !isFetchingData.current &&
    !isLoading &&
    isFetched &&
    pageBottomCoordinates > observerBottomCoordinates
  ) {
    isFetchingData.current = true;
    setPageNumber(prev => prev + 1);
  }
};
