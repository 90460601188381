import { Icon, IconButton } from '@chakra-ui/react';
import _ from 'lodash';
import { IoCall } from 'react-icons/io5';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import useGroupCall from 'src/hooks/useGroupCall';
import { CallSession } from 'src/models/CallSession.model';

interface GroupRandomVoiceCallButtonProps {
  sessions: CallSession[];
}

const RandomGroupCallButton: React.FC<GroupRandomVoiceCallButtonProps> = ({
  sessions,
}) => {
  const { makeGroupCall } = useGroupCall();
  const { currentUser } = useUserDetails();

  const otherGroupMemberUserIds = _.chain(sessions)
    .filter(({ otherUsers }) => otherUsers.id !== currentUser?.id)
    .map(({ otherUsers }) => otherUsers.id)
    .uniq()
    .value();

  const handleCallForExistingMembers = (userIds: number[]) => {
    makeGroupCall({
      receiverUserIds: userIds,
    });
  };

  return (
    <IconButton
      aria-label='voice call'
      variant='ghost'
      size='lg'
      rounded='full'
      icon={<Icon as={IoCall} boxSize='6' color='blue.500' />}
      onClick={() => handleCallForExistingMembers(otherGroupMemberUserIds)}
    />
  );
};

export default RandomGroupCallButton;
