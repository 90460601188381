import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { buildAlertPageRoute } from 'src/routes/alert.route';
import { getDateInLLLFormat } from 'src/utils/data.util';

interface AlertMessageModalProps {
  alertId: number;
  alertMessage: string;
  sender: string;
  messageAt: string;
  messageThreadId: number;
  onClose: () => void;
  isOpen: boolean;
}

export const AlertMessageModal: React.FC<AlertMessageModalProps> = ({
  alertMessage,
  onClose,
  isOpen,
  alertId,
  messageAt,
  sender,
  messageThreadId,
}) => {
  const { push: navigate } = useHistory();
  const { translate } = useTranslate();

  const navigateToAlertPage = () => {
    navigate(buildAlertPageRoute(alertId, messageThreadId));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='sm'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>
          {translate(TranslationKeys.alertStatusReport)}
        </ModalHeader>
        <ModalBody as={VStack} spacing='4'>
          <VStack
            align='start'
            border='1px solid'
            borderColor='gray.400'
            p='2'
            rounded='md'
            bg='gray.200'
            fontSize='lg'
            h='max'
            w='full'
          >
            <Text>
              <Text as='span' fontWeight='semibold'>
                {translate(TranslationKeys.user)}:
              </Text>{' '}
              {sender}
            </Text>
            <Text>
              <Text as='span' fontWeight='semibold'>
                {translate(TranslationKeys.messageTime)}:
              </Text>{' '}
              {getDateInLLLFormat(messageAt)}
            </Text>
            <Text>
              <Text as='span' fontWeight='semibold'>
                {translate(TranslationKeys.message)}:
              </Text>{' '}
              {alertMessage}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter w='full' gridGap='6'>
          <Button w='full' onClick={onClose} variant='outline' _focus={{}}>
            {translate(TranslationKeys.close)}
          </Button>
          <Button w='full' colorScheme='blue' onClick={navigateToAlertPage}>
            {translate(TranslationKeys.reply)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
