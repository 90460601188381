import { useDisclosure } from '@chakra-ui/react';
import { createContext, useState, FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { WakeWordDetectionDialog } from 'src/pages/messages/components/WakeWordDetectionDialog';
import { buildAlertPageRoute } from 'src/routes/alert.route';
import { useWakeWordDetection } from './hooks/use-wake-word-detection';

type HandsFreeOperationContextType = {
  hfoModelDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
  setIsListening: React.Dispatch<React.SetStateAction<boolean>>;
  onOpenAlertInformationProvider: () => void;
  setAlertId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setMessageThreadId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const HandsFreeOperationContext = createContext<HandsFreeOperationContextType>(
  null!,
);

const HandsFreeOperationProvider: FC = ({ children }) => {
  const { hfoModelDisclosure, setIsListening } = useWakeWordDetection();
  const sosInformationProviderModalDisclosure = useDisclosure();
  const [alertId, setAlertId] = useState<number>();
  const [messageThreadId, setMessageThreadId] = useState<number>();

  const { push } = useHistory();

  useEffect(() => {
    if (alertId && messageThreadId) {
      push(buildAlertPageRoute(alertId, messageThreadId));
    }
  }, [alertId, messageThreadId]);

  return (
    <HandsFreeOperationContext.Provider
      value={{
        hfoModelDisclosure,
        setIsListening,
        setAlertId,
        setMessageThreadId,
        onOpenAlertInformationProvider:
          sosInformationProviderModalDisclosure.onOpen,
      }}
    >
      {children}
      {hfoModelDisclosure.isOpen && <WakeWordDetectionDialog />}
    </HandsFreeOperationContext.Provider>
  );
};

const useHandsFreeOperation = () => {
  const context = useContext(HandsFreeOperationContext);
  if (!context) {
    throw new Error(
      'useHandsFreeOperation must be used within a HandsFreeOperationProvider',
    );
  }
  return context;
};

export { useHandsFreeOperation, HandsFreeOperationProvider };
