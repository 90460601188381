export const EnglishTranslationValues = {
  login: 'Login',
  email: 'Email',
  enterPassword: 'Enter Password',
  createOne: 'Create One',
  dontHaveAccount: "Don't have an account? Create One",
  register: 'Register',
  firstName: 'First Name',
  firstNamePlaceholder: 'John',
  lastName: 'Last Name',
  lastNamePlaceholder: 'Doe',
  emailPlaceholder: 'john.doe@example.com',
  confirmPassword: 'Confirm Password',
  createAccount: 'Create an account',
  alreadyHaveAccount: 'Already have an account?',
  home: 'Home',
  emergencySOS: 'Emergency SOS',
  addMoreInfo: 'Add More Information',
  photo: 'Photo',
  video: 'Video',
  voice: 'Voice',
  text: 'Text',
  takeAPicture: 'Take a picture',
  previewFile: 'Preview file',
  recordVideo: 'Record video',
  videoPreview: 'Video preview',
  typeSomething: 'Type something here...',
  recordAudio: 'Record audio',
  previewAudio: 'Preview audio',
  send: 'Send',
  addMoreInfoToAlert: 'Add more information to alert',
  enterMessage: 'Enter message here',
  emergencyAlert: 'Emergency Alert',
  emergencyAlertRaised: 'Emergency Alert Raised',
  moreDetails: 'More details',
  alerts: 'Alerts',
  active: 'Active',
  resolved: 'Resolved',
  alertDetails: 'Alert Details',
  location: 'Location',
  image: 'Image',
  audio: 'Audio',
  markAsResolved: 'Mark as resolved',
  alertIsResolved: 'Alert is resolved',
  searchUsers: 'Search users',
  startSearchingUsers: 'Start searching for users',
  noUsersFound: 'No users found',
  myProfile: 'My Profile',
  enableHfo: 'Enable HFO',
  language: 'Language',
  english: 'English ',
  japanese: 'Japanese',
  logout: 'Logout',
  logoutFailed: 'Logout failed',
  pleaseTryAgain: 'Please try again later',
  switchLanguage: 'Switching language to',
  languageSwitchSuccess: 'Language switch successful',
  areYouSureLogout: 'Are you sure you want to logout?',
  cancel: 'Cancel',
  confirm: 'Confirm',
  profile: 'Profile',
  phoneNumber: 'Phone number',
  gender: 'Gender',
  female: 'Female',
  male: 'Male',
  addressLineOne: 'Address line one',
  addressLineTwo: 'Address line two',
  city: 'City',
  state: 'State',
  postalCode: 'Postal Area Code',
  country: 'Country',
  save: 'Save',
  reset: 'Reset',
  profileUpdateSuccess: 'Profile updated successfully',
  avatarUpdateSuccess: 'Avatar updated successfully',
  updateAvatar: 'Update Avatar',
  removeAvatar: 'Remove Avatar',
  success: 'Success',
  avatarRemovedSuccessfully: 'Avatar removed successfully',
  messages: 'Messages',
  personal: 'Personal',
  groups: 'Groups',
  newPersonalChat: 'New personal chat',
  searchForAUser: 'Search for a user',
  viewProfile: 'View Profile',
  beginConversation: 'Begin a conversation',
  search: 'Search',
  startSpeaking: 'Start speaking',
  edit: 'Edit',
  en: 'en',
  ja: 'Ja',
  connecting: 'Connecting',
  callRejected: 'Call was rejected',
  callMissed: 'Call was missed',
  userUnreachable: 'User is unreachable',
  incomingCall: 'Incoming call',
  newGroupChat: 'New Group Chat',
  name: 'Name ',
  description: 'Description',
  next: 'Next',
  previous: 'Previous',
  createGroup: 'Create group',
  createdGroup: 'Created group',
  addSelectedUser: 'Add selected user',
  viewMembers: 'View Members',
  editGroupInfo: 'Edit Group Info',
  leaveGroup: 'Leave Group',
  members: 'Members',
  admin: 'Admin',
  makeAdmin: 'Make admin',
  removeFromGroup: 'Remove from group',
  editGroup: 'Edit Group',
  groupUpdated: 'Group updated',
  groupUpdatedSuccessfully: 'Group has been updated successfully',
  leaveGroupWarning: 'Are you sure you want to leave this group?',
  emptyGroupMessage: 'No members in this group',
  groupCallWarning: "You can't make a group call without members.",
  calls: 'Calls',
  selectUserToCall: 'Select user to call',
  member: 'Member',
  joined: 'Joined',
  ended: 'Ended',
  duration: 'Duration',
  feeds: 'Feeds',
  noCoverImageFeed: 'No cover image was provided for this feed',
  noPostToShow: 'No posts to show',
  feedCreator: 'Feed creator',
  feedMembers: 'Feed members',
  addFeedMembers: 'Add feed members',
  managePermission: 'Manage permissions',
  manageFeedMemberPermission: 'Manage feed member permissions',
  memberPermission: 'Member Permissions',
  canPost: 'Can post',
  updatePermission: 'Update permissions',
  removeFromFeed: 'Remove from feed',
  removeMember: 'Remove member',
  userRemoveFromFeedWarning:
    'Are you sure you want to remove {user name} from the feed?',
  review: 'Review',
  reviewPost: 'Review post',
  postApproval: 'Post Approval',
  approvePost: 'Approve post',
  rejectPost: 'Reject Post',
  reviewRemarkOptional: 'Review Remarks (optional)',
  addRemark: 'Add your remark for the post',
  submitPostReview: 'Submit post review',
  postReviewUpdateSuccess: 'Post review updated successfully',
  awaitingApproval: 'Awaiting approval',
  approved: 'Approved',
  rejected: 'Rejected',
  writeSomethingHere: 'Write something here',
  posts: 'Posts',
  yourPosts: 'Your Posts',
  like: 'Like',
  comment: 'Comment',
  addYourComment: 'Add your comment',
  showMoreComments: 'Show more comments',
  showLessComments: 'Show less comments',
  viewCount: 'Views',
  loadNewer: 'Load Newer',
  loadingMore: 'Loading more',
  editPost: 'Edit post',
  deletePost: 'Delete post',
  createPost: 'Create a post',
  whatToPost: 'What do you want to post about?',
  document: 'Document',
  post: 'Post',
  images: 'Images',
  documents: 'Documents',
  videos: 'Videos',
  posting: 'Posting',
  newPostAdded: 'New post added',
  updating: 'Updating',
  postUpdated: 'Post updated',
  postUpdateSuccess: 'Your post has been updated successfully',
  areYouSureMessage: "Are you sure? You can't undo this action afterwards.",
  postDelete: 'Post deleted',
  pageCreator: 'Page creator',
  pageMembers: 'Page Members',
  previewChanges: 'Preview changes',
  publishChanges: 'Publish Changes',
  continueEditing: 'Continue Editing',
  pageContentUpdated: 'Page content updated',
  pageContentUpdatedSuccessfully: 'Page content has been updated successfully',
  reviewRequest: 'Review Request',
  yourReviewSubmitted: 'Your review has been submitted',
  waitForAdminReview: 'Please wait for the admin to review your content',
  addPageMembers: 'Add page members',
  addSelectedUsers: 'Add selected users',
  removeFromPage: 'Remove from page',
  removeFromPageWarning:
    'Are you sure you want to remove {User Name} from the Page?',
  pageContent: 'Page content',
  pageRequest: 'Page request',
  discard: 'Discard',
  pageContentDiscarded: 'Page content has been discarded',
  reject: 'Reject',
  accepted: 'Accepted',
  approveRequest: 'Approve request',
  rejectRemark: 'Reject remark',
  reviewRemark: 'Review remark',
  letReviewerKnowRemark: 'Let the page reviewer know your remarks',
  submitPageReview: 'Submit page review',
  reviewRemarksSendSuccess: 'Review remarks send successfully..!',
  remarkSentToUser: 'Remarks has been sended to User',
  pageContentApproved: 'Page content approved',
  insertLink: 'Insert link',
  enterLinkUrl: 'Enter link url',
  insert: 'Insert',
  uploadMediaFromDevice: 'Upload media from device',
  insertMediaUrl: 'Insert media using an url',
  insertMedia: 'Insert media',
  requests: 'Requests',
  commandSampleOne: 'Send message to Kunal, how are you?',
  commandSampleTwo: 'Make a call to John',
  commandSampleThree: 'Send an emergency SOS',
  adminLoginSuccess: 'Admin Login Successfully',
  adminLogoutSuccess: 'Admin Logout Successfully',
  loginFailed: 'Login failed',
  addFeed: 'Add Feed',
  numberOfFeeds: 'Number of feeds',
  feedName: 'Feed name',
  createdBy: 'Created by',
  public: 'Public',
  moderated: 'Moderated',
  feedCreatedAt: 'Feed created at',
  actions: 'Actions',
  rowsPerPage: 'Rows per page',
  pageCount: 'Page 1 of 1',
  page: 'Page',
  of: 'of',
  createFeed: 'Create a feed',
  addCoverImage: 'Add cover image',
  coverImage: 'Cover image',
  feedUrl: 'Feed url',
  openToUsers: 'Open to users',
  allowPostByAnyRegisteredUser: 'Allow post by any registered user',
  feedCreated: 'Feed created',
  feedAddedSuccessful: 'Feed added successfully',
  error: 'Error',
  somethingWentWrong: 'Something went wrong. Please try again',
  pleaseTryAfterSometime: 'Please try after sometime',
  feedEdited: 'Feed edited',
  feedEditedSuccessfully: 'Feed edited successfully',
  deleteFeed: 'Delete Feed',
  areYouSureWarningMessage:
    "Are you sure? You can't undo this action afterwards",
  delete: 'Delete',
  yes: 'Yes',
  no: 'No',
  addPost: 'Add Post',
  addMember: 'Add member',
  noPostFound: 'No post found',
  filters: 'FIlters',
  searchByUser: 'Search by user',
  typeUserName: "Type a user's name",
  selectStartDate: 'Select start date',
  selectEndDate: 'Select end date',
  apply: 'Apply',
  totalNumberPost: 'Total Number of posts',
  totalNumberPostView: 'Total number of posts views',
  update: 'Update',
  moderatePosts: 'Moderate posts',
  archive: 'Archive',
  moderatedMembers: 'Moderated members',
  add: 'Add',
  attachments: 'Attachments',
  sections: 'Sections',
  dashboardSections: 'Dashboard sections',
  title: 'Title',
  type: 'Type',
  position: 'Position',
  addSection: 'Add section',
  addNewDashboardSection: 'Add new dashboard section',
  editDashboardSection: 'Edit dashboard section',
  iconLink: 'Icon link',
  textLink: 'Text link',
  imageLink: 'Image link',
  imageSlider: 'Image slider',
  provideTitleForSection: 'Please provide a title for the section',
  positionMustBeGreater: 'Position must be greater than or equal to 1',
  linkText: 'Link text',
  linkUrl: 'Link url',
  creator: 'Creator ',
  addLink: 'Add link',
  addDashboardSectionLink: 'Add dashboard section link',
  startAt: 'Starts at',
  endsAt: 'Ends at',
  external: 'External',
  editDashboardSectionLink: 'Edit dashboard section link',
  deleteDashboardSectionLink: 'Delete dashboard section link',
  users: 'Users',
  numberOfUsers: 'Number of users',
  avatar: 'Avatar',
  userRegisteredOn: 'User Registered on',
  addUser: 'Add user',
  password: 'Password',
  deleteUser: 'Delete user',
  pages: 'Pages',
  addPage: 'Add Page',
  pageTitle: 'Page title',
  pageUrl: 'Page url',
  createdOn: 'Created on',
  addNewPage: 'Add new page',
  pageCreatedSuccessfully: 'Page created successfully',
  pageInfo: 'Page info',
  editPageMember: 'Edit page member',
  userUpdatedSuccessfully: 'User updated successfully',
  userUpdateFailed: 'User update failed',
  moderateUpdates: 'Moderate updates',
  moderateMembers: 'Moderate members',
  typeYourPageContentHere: 'Type your page content here',
  canUpdate: 'Can update',
  canModerateUpdates: 'Can moderate updates',
  canModerateMembers: 'Can moderate members',
  alertGroups: 'Alert groups',
  isAnonymous: 'Is anonymous',
  addAlertGroup: 'Add alert group',
  nameOfTheGroup: 'Name of the group',
  anonymous: 'Anonymous',
  oneAlertGroupAlreadyExist: 'One alert group already exist',
  editTheAlertGroup: 'Edit the alert group',
  groupMembers: 'Group Members',
  addGroupMember: 'Add group member',
  deleteGroup: 'Delete group',
  exportActivityData: 'Export activity data',
  downloadData: 'Download data',
  from: 'From ',
  to: 'To',
  dataDownloadSuccess: 'Data downloaded successfully',
  noDataFound: 'No data was found in the given date range',
  emptyContent: 'Cannot post empty content',
  postMustHaveContent: 'Your post must have content - text, file, or both',
  commentEditedSuccessfully: 'Comment edited successfully',
  commentAddedSuccessfully: 'Comment added successfully',
  fileTooLarge: 'File is too large',
  fileSizeLimit: 'File size should be less than {}',
  lacsUnitCreated: 'Lacs Unit Created',
  lacsUnitCreatedSuccessfully: 'Lacs Unit Created successfully',
  lacsGroupCreated: 'Lacs Group Created',
  lacsGroupCreatedSuccessfully: 'Lacs Group Created Successfully',
  lacsGroupEdited: 'Lacs Group Edited',
  lacsGroupEditedSuccessfully: 'Lacs Group Edited Successfully',
  audioRecording: 'Audio Recording',
  pageNotFound: 'Page not found',
  goToHome: 'Go to home',
  sosAlertSuccess: 'SOS Alert Raised Successfully',
  failToUpdateLang: 'Failed to update language',
  serverError: 'Server Error',
  pleaseContactAdmin: 'Please contact the administrator',
  unknownErrorOccur: 'An unknown error occurred',
  raiseAnAlert: 'Raised an alert',
  draftPageMember: 'Draft Page Member',
  by: 'By',
  noPagesToShow: 'No pages to show',
  noPagesYet: 'There are no pages yet',
  unknownUser: 'Unknown User',
  accept: 'Accept',
  block: 'Block',
  unblock: 'Unblock',
  youAreBlocked:
    'You are blocked and can no longer send messages in this conversation',
  youHaveBlocked: 'You have blocked this chat. Please unblock to send messages',
  filesTooLargeNotAttached: 'file(s) were too large and were not attached',
  newMessages: 'New Message(s)',
  today: 'Today',
  yesterday: 'Yesterday',
  errorUpdatingGroup: 'Error updating group',
  fetchingGroup: 'Fetching group...',
  previewFiles: 'Preview files',
  selectMembers: 'Select Members',
  messageTooLong: 'Message too long',
  messageCannotBeMoreThan: 'Message cannot be more than 1000 characters',
  failedToSendMessage: 'Failed to send message',
  isTyping: 'is typing...',
  areTyping: 'are typing...',
  more: 'more',
  typing: 'typing...',
  areYouSureYouWantToRemove: 'Are you sure you want to remove',
  fromTheFeed: 'from the feed?',
  thisMember: 'this member',
  you: 'you',
  permissionsUpdated: 'Permissions updated',
  feed: 'Feed',
  failedToCreatePost: 'Failed to create post',
  comments: 'Comments',
  noCommentsToShow: 'No comments to show',
  deleteComment: 'Delete comment',
  updateComment: 'Update comment',
  editComment: 'Edit comment',
  likes: 'Likes',
  nothingMoreToLoad: 'Nothing more to load',
  backgroundUpdating: 'Background updating',
  letThePostAuthorKnow: 'Let the post author know your remarks',
  postReview: 'Post review',
  reviewedBy: 'Reviewed by',
  reviewRemarks: 'Review remarks',
  reviewedAt: 'Reviewed at',
  approve: 'Approve',
  usersWhoLikePost: 'Users who liked this post',
  noFeedsYet: 'There are no feeds yet',
  loadingMap: 'Loading map',
  pleaseEnterEmail: 'Please enter your email',
  pleaseEnterValidEmail: 'Please enter a valid email',
  pleaseEnterPassword: 'Please enter your password',
  failedToLoginIn: 'Failed to login',
  unknownErrorOccurTryAgain:
    'An unknown error occurred. Please try again later.',
  inValidCredentials: 'Invalid credentials. Please check your login data.',
  addMembersToGroup: 'Add members to group',
  fromTheGroup: 'from the group?',
  failedMakingAdmin: 'Failed to make admin',
  failedRemovingAdmin: 'Failed to remove admin',
  removeAdmin: 'Remove admin privileges',
  enterNameForGroup: 'Enter a name for the group',
  groupNameLongerThan: 'Group name must be longer than 3 characters',
  groupNameNotLongerThan: 'Group name must not be longer than 30 characters',
  createGroupChat: 'Create one using the + button below',
  noGroupChatsYet: "You don't have any group chats yet",
  noPersonalChatsYet: "You don't have any personal chats yet",
  createPersonalChat: 'Create one using the + button below',
  errorFetchingGroupInfo:
    'There was an error fetching group info. Please try again later',
  groupDescription: 'Group Description',
  groupInfo: 'Group Info',
  loadingChats: 'Loading chats...',
  sentAFile: 'Sent a file',
  removeVideo: 'Remove video',
  errorApprovingPageContent: 'Error while approving page content',
  errorDiscardingPageContent: 'Error while discarding page content',
  errorSubmittingYourReview: 'Error while submitting your review',
  pageContentRejected: 'Page content has been rejected',
  reviewForYourRequest: 'Review for your request',
  publishingChanges: 'Publishing changes',
  youHaveSuccessfullyCreatedAccount: 'You have successfully created an account',
  pleaseLoginToContinue: 'Please login to continue',
  failedToRegister: 'Failed to register',
  userWithSameNameAlreadyExist:
    'User with the same name already exists. Please login with that email or use another one.',
  connect: 'Connect',
  address: 'Address',
  noDataToShow: 'No data to show currently',
  loading: 'Loading',
  addImage: 'Add Image',
  dashboardSectionLink: 'Dashboard Section Link',
  updateImage: 'Update image',
  applyFilter: 'Apply Filter',
  canModeratePosts: 'Can moderate posts',
  editMember: 'Edit Member',
  errorRemovingMedia: 'Error removing media',
  somethingWentWrongMedia: 'Something went wrong while removing media',
  errorUpdatingPost: 'Error updating post',
  somethingWentWrongUpdatingPost: 'Something went wrong while updating post',
  feedMemberDeleted: 'Feed member deleted',
  editFeed: 'Edit Feed',
  createPage: 'Create Page',
  creatingPage: 'Creating page',
  errorUpdatingPageContent: 'Error updating page content',
  errorWhileDiscardingPageContent: 'Error while discarding page content',
  currentPageContent: 'Current page content',
  draftPageContent: 'Draft page content',
  errorSendingReviewRemark: 'Error while sending review remark',
  pageUpdatedSuccessfully: 'Page updated successfully',
  savingChanges: 'Saving changes',
  pageMemberDeleted: 'Page member deleted',
  draftRequest: 'Draft Request',
  userWithSamePhoneNumberAlreadyExists:
    'User with the same phone number already exists',
  userCreatedSuccessfully: 'User created successfully',
  userAlreadyExists: 'User already exists',
  user: 'User',
  oneAnonymousAlertGroupAlreadyExist: 'One anonymous alert group already exist',
  userAlreadyPartOfAlertGroup: 'User is already part of an alert group',
  callerReceiverCantBeSame: 'Caller and receiver cannot be the same',
  youMustBeCallReceiverToAcknowledge:
    'You must be the receiver of the call session to acknowledge it',
  youMustBeCallReceiverToReject:
    'You must be the receiver of the call session to reject it',
  youMustBeCallReceiverToAccept:
    'You must be the receiver of the call session to accept it',
  youMustBeCallReceiverToLeave:
    'You must be the receiver of the call session to leave it',
  callSessionNotFound: 'Call session not found',
  errorWhileCreatingCall: 'Error while creating call',
  callNotFound: 'Call not found',
  youDontHavePermissionToCreateDashboardSection:
    "You don't have permission to create a dashboard section",
  memberAlreadyExistInFeed: 'Member already exist in the feed',
  youAreNotAllowedToAddMember: 'You are not allowed to add a member',
  youAreNotAllowedToPost: 'You are not allowed to post',
  feedAlreadyExist: 'Feed already exist',
  feedNotFound: 'Feed not found',
  userIsNotGroupAdmin: 'User is not a group admin',
  lacsGroupNotCreated: 'Lacs group not created',
  youAreNotAuthorizedToUpdateLacsGroup:
    'You are not authorized to update Lacs Group',
  youAreNotAllowedToAddAdmin: 'You are not allowed to add an admin',
  youCannotRemoveYourselfAsAdmin: 'You cannot remove yourself as an admin',
  youDonotHavePermissionToCreatePage:
    'You do not have permission to create a page',
  youDonotHavePermissionToUpdatePage:
    'You do not have permission to update the page',
  unableToIdCommand: 'Unable to identify the command, Please try again',
  unableToIdUser: 'Unable to identify the user, Please try again',
  unableToExecuteCommand:
    'Unable to execute the command at this moment, Please try again later',
  invalidPostalAreaCode: 'Invalid postal area code',
  invalidUserId: 'Invalid user id',
  forgotPassword: 'Forgot Password?',
  searchingForUsers: 'Searching for users',
  noRequestsPending: 'No requests pending',
  pageDraft: 'Page Draft',
  contentStatus: 'Content Status',
  editContent: 'Edit Content',
  noContentFound: 'No content found',
  draft: 'Draft',
  youHaveNoCallsYet: 'You have no calls yet',
  createACall: 'Create a call by tapping the call button below',
  youDontHaveGroupCalls: 'You do not have any group calls yet',
  incomingGroupCall: 'Incoming group call',
  reviewBy: 'Review by',
  tryToSay: 'Try to say',
  youAreNotAuthorizedToUpdateLacsUnit:
    'You are not authorized to update Lacs Unit',
  lacsGroups: 'LACS Groups',
  createLacsGroup: 'Create LACS Group',
  organizationName: 'Organization Name',
  subdomain: 'Subdomain',
  departmentName: 'Department Name',
  contactPersonName: 'Contact Person Name',
  contactPersonDesignation: 'Contact Person Designation',
  shareDataAmongUnits: 'Share Data Among Units',
  editLacsGroup: 'Edit LACS Group',
  groupAdminList: 'Group Admin List',
  existingAdmins: 'Existing Admins',
  createLacsUnit: 'Create LACS Unit',
  unitId: 'Unit Id',
  unitName: 'Unit Name',
  IsCloudUnit: 'Is Cloud Unit',
  editLacsUnit: 'Edit LACS Unit',
  cloudUnit: 'Cloud Unit',
  NoOfAdmins: 'No. of Admins',
  addUnit: 'Add Unit',
  adminAddedSuccessfully: 'Admin added successfully',
  addGroupAdmins: 'Add Group Admins',
  created: 'Created',
  numberOfUnits: 'Number of Units',
  totalGroupAdmins: 'Total Group Admins',
  viewGroupAdmins: 'View Group Admins',
  ViewAdmins: 'View Admins',
  addGroup: 'Add Group',
  youAreNotAllowedToRemoveAdmin: 'You are not allowed to remove admin',
  youAreAlreadyAnAdminOfThisUnit: 'You are already an admin of this unit',
  lacsUnitWithThisIdAlreadyExist: 'Lacs unit with this id already exist',
  youAreNotAuthorizedToCreateLacsUnit:
    'You are not authorized to create lacs unit',
  unableToCreateLacsUnitThisTime: 'Unable to create lacs unit this time',
  lacsUnitNotFound: 'Lacs unit not found',

  // Validation messages
  pleaseEnterComment: 'Please enter your comment',
  pleaseEnterFirstName: 'Please enter your first name',
  pleaseEnterConfirmPassword: 'Please enter your password again',
  passwordMismatch: "The passwords you entered don't match",
  pleaseEnterLastName: 'Please enter your last name',
  pleaseEnterValidPhoneNumber: 'Please enter a valid phone number',
  pleaseSelectGender: 'Please select your gender',
  pleaseEnterValidUrl: 'Please enter a valid URL',
  pleaseEnterUrl: 'Please enter a URL',
  groupNameMinLength: 'Group name must be longer than 3 characters',
  groupNameMaxLength: 'Group name must not be longer than 30 characters',
  pleaseEnterGroupName: 'Please enter a name for the group',
  pleaseEnterValidPostalCode: 'Please enter a valid postal area code.',
  pleaseEnterGroupDescription: 'Please enter a description for the group',
  pleaseEnterFeedName: 'Please enter a name for the feed',
  feedIsPublicOrNot: 'Please specify if the feed is public or not',
  openToUserOrNot: 'Please specify if the feed is open to users or not',
  anyUserCanPostOrNot: 'Please specify if any user can post or not',
  feedIsModeratedOrNot: 'Please specify if the feed is moderated or not',
  pleaseEnterOrganizationName: 'Please enter the organization name',
  pleaseEnterDepartmentName: 'Please enter the department name',
  pleaseEnterPhoneNumber: 'Please enter the phone number',
  pleaseEnterCity: 'Please enter the city',
  pleaseEnterState: 'Please enter the state',
  pleaseEnterCountry: 'Please enter the country',
  pleaseEnterLacsUnitId: 'Please enter the unit ID',
  pleaseEnterUnitName: 'Please enter the unit name',
  pleaseEnterDescription: 'Please enter the description',
  pleaseEnterLocationName: 'Please enter the location name',
  cloudUnitOrNot: 'Please specify if the unit is a cloud unit or not',
  pleaseSelectSectionType: 'Please select the type',
  pleaseEnterValidPosition: 'Please provide a valid position for the section',
  pleaseEnterPosition: 'Please provide a position for the section',
  pleaseEnterTitle: 'Please provide a title for the section',
  titleCannotBeLongerThan: 'Title cannot be longer than 60 characters',
  linkTitleCannotBeLongerThan: 'Link title cannot be longer than 60 characters',
  pleaseEnterLinkTitle: 'Please enter a title for the link',
  pleaseEnterValidLinkUrl: 'Please enter a valid link URL',
  pleaseSpecifyIfLinkIsExternal: 'Please specify if the link is external',
  pleaseEnterPageTitle: 'Please enter the page title',
  pageTitleMustBeAtLeast: 'Page title must be at least 4 characters',
  pleaseSelectPrivacySetting: 'Please select a privacy setting',
  pleaseSelectModerationSetting: 'Please select a moderation setting',
  pleaseSelectUserAccessSetting: 'Please select a user access setting',
  feedNameShouldBeAtLeast4Characters:
    'Feed name should be at least 4 characters',
  feedNameShouldBeAtMost30Characters:
    'Feed name should be at most 30 characters',
  applicationHeading: 'X-FACE',
  sentAnImage: 'Sent an image',
  sentAVideo: 'Sent a video',
  sentAnAudio: 'Sent an audio',
  stop: 'Stop',
  yourCurrentLocation: 'Your current location',
  asr: 'ASR',
  startASRChat: 'Start ASR Chat',
  messageSendSuccessfully: 'Message sent successfully',
  youAreNotAMemberOfThisGroup: 'You are not a member of this group',
  incomingRadioCommunication: 'Incoming Radio Communication',
  additionalDetails: 'Additional Details',
  addOneMoreMemberToStartAGroupCall:
    'Add one more member to start a group call',
  memberAlreadyExistInDefaultGroup:
    'Member already exists in the default alert group',
  defaultAlertGroupNotFound: 'Default alert group not found',
  nothingToShow: 'Nothing to show',
  quickUpdate: 'Quick Update',
  statusReport: 'Status Report',
  statusUpdate: 'Status Update',
  links: 'Links',
  close: 'Close',
  loginToReport: 'Please Login to report',
  alertStatusReport: 'Alert Status Report',
  disasterCategory: 'Disaster Category',
  message: 'Message',
  chooseReportCategory: 'Choose Report Category',
  done: 'Done',
  reply: 'Reply',
  report: 'Report',
  accountDeactivatedByAdmin:
    'Account has been deactivated by admin. Please contact admin',
  messageTime: 'Message Time',
  alertNotFound: 'Alert not found',
  userNotInAlertGroup: 'User not in alert group',
};
