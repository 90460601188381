import { HStack, Input, IconButton, Icon } from '@chakra-ui/react';
import { MdSend } from 'react-icons/md';

import { FOOTER_HEIGHT } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

interface AlertChatInputProps {
  isResolved: boolean;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnTextInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  msgContent: string;
}

export const AlertChatInput: React.FC<AlertChatInputProps> = ({
  handleKeyPress,
  handleOnTextInputChange,
  isResolved,
  msgContent,
  onClick,
}) => {
  const { translate } = useTranslate();
  return (
    <HStack w='full' h={FOOTER_HEIGHT} px='2' hidden={isResolved}>
      <Input
        onKeyPress={handleKeyPress}
        onChange={handleOnTextInputChange}
        value={msgContent}
        pl='4'
        placeholder={translate(TranslationKeys.typeSomething)}
      />
      <IconButton
        rounded='md'
        colorScheme='blue'
        p='2'
        icon={<Icon as={MdSend} boxSize='8' />}
        aria-label='send chat button'
        onClick={onClick}
      />
    </HStack>
  );
};
