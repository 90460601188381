import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { CommonInputBox } from 'src/components/Common/CommonInputBox';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { useUpdateDetails } from '../../hooks/use-update-details';

interface UpdateDetailsFormProps {
  setIsEditable: (isEditable: boolean) => void;
}

export const UpdateDetailsForm: React.FC<UpdateDetailsFormProps> = ({
  setIsEditable,
}) => {
  const { translate } = useTranslate();
  const { register, handleSubmit, control, reset, errors, formSubmitHandle } =
    useUpdateDetails({ setIsEditable });

  const makeFormUneditable = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    reset();
    setIsEditable(false);
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(formSubmitHandle)}>
      <Grid gap='2' px='1'>
        <CommonInputBox
          label={translate(TranslationKeys.firstName)}
          value='firstName'
          register={register}
          error={errors.firstName?.message}
        />
        <CommonInputBox
          label={translate(TranslationKeys.lastName)}
          value='lastName'
          register={register}
          error={errors.lastName?.message}
        />
        <CommonInputBox
          label={translate(TranslationKeys.email)}
          value='email'
          register={register}
          error={errors.email?.message}
          inputType='email'
        />
        <CommonInputBox
          label={translate(TranslationKeys.phoneNumber)}
          value='phoneNumber'
          register={register}
          error={errors.phoneNumber?.message}
          control={control}
        />
        <FormControl isRequired isInvalid={!!errors.gender}>
          <FormLabel>{translate(TranslationKeys.gender)}</FormLabel>
          <Controller
            name='gender'
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} my='2'>
                <Radio mr='2' value='Female' name='gender'>
                  {translate(TranslationKeys.female)}
                </Radio>
                <Radio value='Male' name='gender' ml='2'>
                  {translate(TranslationKeys.male)}
                </Radio>
              </RadioGroup>
            )}
          />
          <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
        </FormControl>
        <CommonInputBox
          label={translate(TranslationKeys.address)}
          value='address'
          register={register}
          error={errors.address?.message}
        />
        <CommonInputBox
          label={translate(TranslationKeys.city)}
          value='city'
          register={register}
          error={errors.city?.message}
        />
        <CommonInputBox
          label={translate(TranslationKeys.state)}
          value='state'
          register={register}
          error={errors.state?.message}
        />
        <CommonInputBox
          label={translate(TranslationKeys.postalCode)}
          value='postalAreaCode'
          register={register}
          error={errors.postalAreaCode?.message}
          inputType='number'
        />
        <CommonInputBox
          label={translate(TranslationKeys.country)}
          value='country'
          register={register}
          error={errors.country?.message}
        />

        <Flex gridColumn='1/-1' justifyContent='center' my='2'>
          <Button type='submit' mx='1' colorScheme='blue'>
            {translate(TranslationKeys.save)}
          </Button>
          <Button mx='1' colorScheme='red' onClick={makeFormUneditable}>
            {translate(TranslationKeys.cancel)}
          </Button>
        </Flex>
      </Grid>
    </form>
  );
};
