import _ from 'lodash';

import {
  useHandsFreeOperation,
  useTranscribeMessage,
} from 'src/hoc/HandsFreeOperationProvider';

import { useCommonAudioStream } from './useCommonAudioStream';

export const useAlertText = (onClose: () => void) => {
  const { setStartTime, transcribeMessage, setTranscribeMessage } =
    useTranscribeMessage();

  const { setIsListening } = useHandsFreeOperation();

  const {
    isRecording,
    stopRecording,
    endRecording,
    startRecording,
    resetAudioRecorder,
  } = useCommonAudioStream(setStartTime, setTranscribeMessage);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Do not allow input when recording
    if (isRecording) {
      return;
    }
    setTranscribeMessage(event.target.value);
  };

  const isValidTextContent = _.chain(transcribeMessage)
    .trim()
    .isEmpty()
    .value();

  const cleanUp = () => {
    endRecording();
    stopRecording();
    setIsListening(true);
    onClose();
  };

  const restartRecording = () => {
    setTranscribeMessage('');
    startRecording();
  };

  return {
    transcribeMessage,
    isValidTextContent,
    isRecording,
    restartRecording,
    cleanUp,
    handleInputChange,
    stopRecording,
    resetAudioRecorder,
  };
};
