import { useState } from 'react';
import { axiosInstance } from 'src/config';
import { CONVERT_TO_PERCENTAGE } from 'src/constants';
import { SOSMediaUpdateDto } from 'src/dto/SoS.dto';
import { useUserLocation } from 'src/hoc';
import { updateAlertMediaApi } from '../alerts.api';

export const useUploadAlertMedia = () => {
  const [progress, setProgress] = useState(0);
  const { locationStream } = useUserLocation();

  const updateAlertMedia = (payload: SOSMediaUpdateDto) => {
    const { formData, url } = updateAlertMediaApi({
      ...payload,
      latitude: locationStream?.latitude.toString(),
      longitude: locationStream?.longitude.toString(),
    });

    return axiosInstance.put<void>(url, formData, {
      onUploadProgress: progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * CONVERT_TO_PERCENTAGE,
        );
        setProgress(progress);
      },
    });
  };

  return { updateAlertMedia, progress };
};
