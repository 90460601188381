import { Box, HStack, Icon, ListItem, Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { AlertMedia } from 'src/models/Alerts.model';
import { buildAlertPageRoute } from 'src/routes/alert.route';
import {
  getAlertMediaPreview,
  getAlertSubtitle,
} from 'src/utils/get-alert-details';

export interface AlertItemsProps {
  alertId: number;
  avatarComponent?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  alertMedias?: AlertMedia[];
  textContent?: string;
  isResolved: boolean;
  messageThreadId: number;
}

const AlertItem: React.FC<AlertItemsProps> = ({
  alertId,
  title,
  subtitle,
  avatarComponent,
  alertMedias,
  textContent,
  isResolved,
  messageThreadId,
}) => {
  const { translate } = useTranslate();
  const history = useHistory();

  const handleOnClick = () => {
    history.push(buildAlertPageRoute(alertId, messageThreadId));
  };

  const alertMedia = _.head(alertMedias);

  const captionKey = getAlertSubtitle(alertMedia);

  const getRightIcon = () => {
    if (isResolved) {
      return (
        <Box border='2px solid' px='1' rounded='md' fontWeight='semibold'>
          Resolved
        </Box>
      );
    }
    if (alertMedia) {
      return <Icon boxSize='2rem' as={getAlertMediaPreview(alertMedia)} />;
    }

    return null;
  };

  return (
    <ListItem
      as={HStack}
      py='2'
      onClick={() => handleOnClick()}
      cursor='pointer'
    >
      {avatarComponent}
      <HStack
        width='full'
        justifyContent='space-between'
        borderBottom='1px solid #E2E8F0'
        gridGap='2'
      >
        <VStack spacing='1' align='start' w='full'>
          <Text fontWeight='bold'>{title}</Text>
          {captionKey ? (
            <Text fontWeight='normal' color='gray.500' noOfLines={1}>
              {textContent ?? alertMedia?.caption ?? translate(captionKey)}
            </Text>
          ) : (
            <Text fontWeight='normal' color='gray.500'>
              {translate(TranslationKeys.raiseAnAlert)}
            </Text>
          )}
          <Text fontWeight='normal' color='gray.500' fontSize='15px'>
            {subtitle}
          </Text>
        </VStack>
        {getRightIcon()}
      </HStack>
    </ListItem>
  );
};

export default AlertItem;
