import { VStack, Text, Avatar, Flex, IconButton } from '@chakra-ui/react';
import { MdMessage, MdCall } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useChat } from 'src/hooks/use-chat';
import useNewCall from 'src/hooks/useNewCall';
import { User } from 'src/models';
import { getUserFullName } from 'src/utils';

interface UserBasicDetailsProps {
  user: User;
}

export const UserBasicDetails: React.FC<UserBasicDetailsProps> = ({ user }) => {
  const { userId }: { userId: string } = useParams();
  const { startChat } = useChat(+userId);
  const { currentUser } = useUserDetails();
  const { makePersonalCall } = useNewCall();

  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      alignItems='center'
      w='full'
      gridGap='6'
      bg='gray.600'
      p={{ base: '4', sm: '6' }}
      rounded='md'
    >
      <Avatar src={user?.avatarUrl} size='2xl' />
      <VStack
        align={{ base: 'center', sm: 'start' }}
        lineHeight='none'
        color='white'
        textAlign={{ base: 'center', sm: 'start' }}
      >
        <Text fontWeight='bold' fontSize={{ base: '2xl', sm: '3xl' }}>
          {getUserFullName(user).toUpperCase()}
        </Text>
        <Text fontSize='lg'>{user.email}</Text>
        {currentUser && user.id !== currentUser.id && (
          <Flex pt='2' gridGap='2'>
            <IconButton
              rounded='full'
              onClick={startChat}
              aria-label='message'
              colorScheme='blue'
            >
              <MdMessage fontSize='24' />
            </IconButton>
            <IconButton
              rounded='full'
              onClick={() => makePersonalCall({ ReceiverId: user.id })}
              aria-label='call'
              colorScheme='blue'
            >
              <MdCall fontSize='24' />
            </IconButton>
          </Flex>
        )}
      </VStack>
    </Flex>
  );
};
