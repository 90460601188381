import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getAllPersonalCalls } from 'src/apis/personal-calls.api';
import { PersonalCallResponse, PersonalCalls } from 'src/models/PersonalCall';
import useArrayDataQuery from 'src/utils/query-utils/useArrayDataQuery';

const kPersonalCallsQueryKey = 'personalCalls';

export const usePersonalCallQueryClient = () => {
  return useArrayDataQuery<PersonalCalls>({
    queryKey: kPersonalCallsQueryKey,
  });
};

const usePersonalCalls = () => {
  const { data, ...queryMetadata } = useQuery<PersonalCallResponse>(
    [kPersonalCallsQueryKey],
    () => getAllPersonalCalls(),
  );

  const personalCalls = useMemo(() => {
    return data?.calls ?? [];
  }, [data]);

  return {
    calls: personalCalls,
    queryMetadata,
  };
};

export default usePersonalCalls;
