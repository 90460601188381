import { useDisclosure } from '@chakra-ui/react';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  subscribeNewIncomingAlert,
  unsubscribeNewIncomingAlert,
} from 'src/apis/socket.io/events/alerts.socket.io';
import {
  onNewAlertMessage,
  unsubscribeNewAlertMessage,
} from 'src/apis/socket.io/messages.socket.io';
import { MessageDto } from 'src/dto/messages.dto';
import { useCustomQueryClient } from 'src/hoc';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { Alert } from 'src/models/Alerts.model';
import { MessageType } from 'src/models/Message.model';
import getAllMessagesQueryKey from 'src/pages/chat/hooks/getMessagesQueryKey';
import { SOSAlertDialog } from 'src/pages/home/components/SOSAlertDialog';
import { ValueCallback } from 'src/types/common.type';
import { getUserFullName } from 'src/utils';

import { AlertMessageModal } from './alert-message-modal';

interface UserSOSAlertWrapperProps {
  children: React.ReactNode;
}

interface NewAlertMessage {
  alertMessage: string;
  alertId?: number;
  sender: string;
  messageAt: string;
  messageThreadId: number;
}

type AlertMessageDto = MessageDto & {
  alertId: number;
};

export const UserSOSAlertWrapper: React.FC<UserSOSAlertWrapperProps> = ({
  children,
}) => {
  const { location } = useHistory();
  const { currentUser } = useUserDetails();
  const alertModalDisclosure = useDisclosure();
  const alertMessageDisclosure = useDisclosure();
  const { queryClient } = useCustomQueryClient();

  const [alertInfo, setAlertInfo] = useState<Alert>();
  const [alertMessage, setAlertMessage] = useState<NewAlertMessage>();

  const handleIncomingALert = useCallback((alert: Alert) => {
    if (alert) {
      setAlertInfo(alert);
    }
  }, []);

  const handleNewMessage = useCallback<ValueCallback<AlertMessageDto>>(
    newMessage => {
      const messageThreadId = Number(_.last(location.pathname.split('/')));
      if (newMessage.messageType !== MessageType.alertChat) {
        return;
      }

      if (newMessage.SenderId === currentUser?.id) {
        return;
      }

      if (messageThreadId && newMessage.MessageThreadId === messageThreadId) {
        return;
      }

      if (!newMessage.sender) {
        return;
      }

      setAlertMessage({
        alertMessage: newMessage.text,
        alertId: newMessage?.alertId,
        messageAt: newMessage.messageAt,
        sender: getUserFullName(newMessage.sender),
        messageThreadId: newMessage.MessageThreadId,
      });

      queryClient.refetchQueries([
        getAllMessagesQueryKey(newMessage.MessageThreadId),
      ]);
    },
    [currentUser?.id, location.pathname],
  );

  useEffect(() => {
    onNewAlertMessage(handleNewMessage);

    return () => unsubscribeNewAlertMessage(handleNewMessage);
  }, [handleNewMessage]);

  useEffect(() => {
    subscribeNewIncomingAlert(handleIncomingALert);
    return () => {
      unsubscribeNewIncomingAlert(handleIncomingALert);
    };
  });

  useEffect(() => {
    // if alert is present then we open alert modal
    if (alertInfo) {
      alertModalDisclosure.onOpen();
    }
  }, [alertInfo]);

  useEffect(() => {
    if (alertMessage && alertMessage.alertId) {
      alertMessageDisclosure.onOpen();
    }
  }, [alertMessage]);

  return (
    <>
      {children}
      {alertInfo && alertModalDisclosure.isOpen && (
        <SOSAlertDialog
          key={alertInfo.id}
          alertData={alertInfo}
          {...alertModalDisclosure}
        />
      )}
      {alertMessage &&
        alertMessage?.alertId &&
        alertMessageDisclosure.isOpen && (
          <AlertMessageModal
            alertMessage={alertMessage.alertMessage}
            sender={alertMessage.sender}
            messageAt={alertMessage.messageAt}
            alertId={alertMessage.alertId}
            messageThreadId={alertMessage.messageThreadId}
            {...alertMessageDisclosure}
          />
        )}
    </>
  );
};
