import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { createPersonalCall } from 'src/apis/calls.api';
import { CreatePersonalCallDto } from 'src/dto/calls.dto';
import { useUserLocation } from 'src/hoc/UserLocationContext';
import { Call } from 'src/models/Call.model';
import { buildCallPageRoute } from 'src/routes/routeList';
import { useToast } from './use-toast';

const useNewCall = () => {
  const { showToast } = useToast();
  const history = useHistory();
  const { locationStream } = useUserLocation();
  const newPersonalCallMutation = useMutation(createPersonalCall);

  const goToCall = (callId: Call['id']) => {
    history.push(buildCallPageRoute(callId.toString()));
  };

  const makePersonalCall = async (data: CreatePersonalCallDto) => {
    // before making a call, ask user for permission to use microphone and camera and if user denies, show a toast message
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
    } catch (error) {
      showToast({
        title: 'Please grant permission to use microphone and camera',
        status: 'warning',
      });
      return;
    }

    const createdCall = await newPersonalCallMutation.mutateAsync({
      ...data,
      ...locationStream,
    });
    goToCall(createdCall.id);
  };

  return { makePersonalCall };
};

export default useNewCall;
