import { Center, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';

import { useAvatarImage } from '../hooks/use-avatar-image';
import { useUpdateDetails } from '../hooks/use-update-details';
import { UpdateDetailsForm } from './components/updateDetailsForm';
import { UserWithAvatar } from './components/userWithAvatar';
import { UserWithoutAvatar } from './components/userWithoutAvatar';

interface EditableFormProps {
  setIsEditable: (isEditable: boolean) => void;
}

const EditableForm: React.FC<EditableFormProps> = ({ setIsEditable }) => {
  const { currentUser: user } = useUserDetails();

  const { avatarSrc, handleImageUpload, removeAvatarMutation, setAvatarSrc } =
    useAvatarImage();

  const { reset } = useUpdateDetails({ setIsEditable });

  useEffect(() => {
    if (user) reset(user);
    if (user?.avatarUrl) {
      setAvatarSrc(user.avatarUrl);
    }
  }, [user]);

  return (
    <VStack w='full' align='start'>
      <Center w='full' bg='gray.200' rounded='md'>
        {avatarSrc || user?.avatarUrl ? (
          <UserWithAvatar
            avatarSrc={avatarSrc}
            removeAvatarMutation={removeAvatarMutation}
            handleImageUpload={handleImageUpload}
          />
        ) : (
          <UserWithoutAvatar handleImageUpload={handleImageUpload} />
        )}
      </Center>
      <UpdateDetailsForm setIsEditable={setIsEditable} />
    </VStack>
  );
};

export default EditableForm;
