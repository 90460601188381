import * as _ from 'lodash';
import { useEffect } from 'react';

import { useCallSessionsContext } from 'src/hoc/call-sessions.provider';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { PeerConnectionContext } from 'src/pages/peer-call/context/peer-connection.context';

import { useUserMedia } from '../../context/user-media.context';
import usePeerCall from '../../hooks/usePeerCall';
import { useStreamControl } from '../../hooks/useStreamControl';

const PeerConnectionProvider: React.FC = ({ children }) => {
  const {
    callPeer,
    calls,
    peerStreams,
    dataConnections,
    peerAudioStatus,
    peerVideoStatus,
    cleanupPeer,
    peer,
    cleanupRemovedPeerCall,
  } = usePeerCall();
  const { localStream } = useUserMedia();
  const { callSessions } = useCallSessionsContext();
  const { currentUser } = useUserDetails();
  const {
    isAudioMuted,
    isFrontCamera,
    isVideoMuted,
    switchCamera,
    toggleAudio,
    toggleVideo,
    mutePeerStream,
    unmutePeerStream,
  } = useStreamControl(calls, dataConnections, peerStreams);

  useEffect(() => {
    if (_.isEmpty(callSessions)) {
      return;
    }

    if (!localStream || !currentUser || !peer) {
      return;
    }

    _.forEach(callSessions, session => {
      if (session.disconnectedAt) {
        cleanupRemovedPeerCall(session.ReceiverId);
        return;
      }
      if (session.joinedAt && session.ReceiverId !== currentUser.id) {
        callPeer(session.ReceiverId.toString(), localStream, peer);
      }
    });
  }, [callSessions, currentUser, localStream, peer]);

  useEffect(() => {
    return () => {
      cleanupPeer();
    };
  }, []);

  const contextValue = {
    peerAudioStatus,
    peerVideoStatus,
    peerStreams,
    mutePeerStream,
    unmutePeerStream,
    isAudioMuted,
    isFrontCamera,
    isVideoMuted,
    switchCamera,
    toggleAudio,
    toggleVideo,
  };

  return (
    <PeerConnectionContext.Provider value={contextValue}>
      {children}
    </PeerConnectionContext.Provider>
  );
};

export default PeerConnectionProvider;
