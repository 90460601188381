import _ from 'lodash';

import { ALERT_MEDIA_TYPES } from 'src/constants/files.constants';
import { AlertMedia } from 'src/models/Alerts.model';

import { AlertMediaSlider } from './AlertMediaSlider';
import { NothingToShow } from './NothingToShow';

interface ImageAlertMediaProps {
  alertMedia: AlertMedia[] | undefined;
  fromAlertDialog?: boolean;
}

export const ImageAlertMedia: React.FC<ImageAlertMediaProps> = ({
  alertMedia,
  fromAlertDialog = false,
}) => {
  const imageMedia = _.filter(alertMedia, media =>
    media.type.startsWith(ALERT_MEDIA_TYPES.image),
  );

  if (_.isEmpty(imageMedia)) {
    return fromAlertDialog ? null : <NothingToShow />;
  }

  return <AlertMediaSlider alertMedia={imageMedia} />;
};
