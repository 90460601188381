import { HStack, ListItem, Text, VStack, Box, Center } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { MutableRefObject, useEffect, useState, memo } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { LacsColors } from 'src/constants/common.constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { ColorShaderMap } from 'src/utils';
import { listVariants } from 'src/constants/calls.constant';
import { Call } from 'src/models/Call.model';

import { CallHistory } from './CallHistory';
import CallIcons from './CallIcons';
import { getOtherUserId } from './getOtherUserId';
import PersonalVoiceCallButton from './PersonalVoiceCallButton';
import { getColorForCallIcon } from '../utils/call.util';

export interface CallItemProps {
  callerId: number;
  receiverId: number;
  joinedAt: Date | null;
  avatarComponent?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  isVisible: boolean;
  onClick?: () => void;
  callStatus: Call['callStatus'];
  observerRef: MutableRefObject<HTMLDivElement | null> | null;
  hasMorePersonalCalls?: boolean;
}

const CallItem: React.FC<CallItemProps> = memo(
  ({
    callerId,
    receiverId,
    joinedAt,
    avatarComponent,
    title,
    subtitle,
    isVisible,
    onClick: handleVisibility,
    callStatus,
    observerRef,
    hasMorePersonalCalls,
  }) => {
    // get current user
    const { currentUser } = useUserDetails();
    // get Other User Id
    const [otherUserId, setOtherUserId] = useState<number>();

    useEffect(() => {
      if (currentUser) {
        setOtherUserId(
          getOtherUserId(
            { CallerId: callerId, ReceiverId: receiverId },
            currentUser.id,
          ),
        );
      }
    }, [currentUser, callerId, receiverId]);

    const blueOrTransparentBorder = isVisible
      ? ColorShaderMap.blue[500]
      : LacsColors.transparent;

    const grayTransparentOrBlueBorder = !isVisible
      ? ColorShaderMap.gray[200]
      : ColorShaderMap.blue[500];

    return (
      <Box
        w='full'
        as={motion.div}
        variants={listVariants}
        cursor='pointer'
        ref={observerRef}
      >
        <ListItem
          onClick={hasMorePersonalCalls ? handleVisibility : undefined}
          border={isVisible ? '1px solid' : '1px solid'}
          borderColor={blueOrTransparentBorder}
          borderBottomColor={grayTransparentOrBlueBorder}
          as={HStack}
          py='2'
          px='2'
        >
          {avatarComponent}
          <VStack spacing='0' align='start' w='full'>
            <Text fontWeight='bold' noOfLines={1}>
              {title}
            </Text>
            <HStack w='full'>
              {/* 
             - fetch current user
             - compare if current user is caller it means that the call is outgoing
             - if the current user is receiver , it means that the call is incoming
            

             - make individual components fot them

             - check if call is joined..simply check value of joinedAt
             - if the call is joined so no matter weather the call is incoming or outgoing it will always green
             - if call is not joined by anyone so  icons will be red
             */}

              {currentUser && (
                <CallIcons
                  callerId={callerId}
                  currentUser={currentUser}
                  callStatus={callStatus}
                  color={getColorForCallIcon(joinedAt)}
                />
              )}

              <Text
                w='full'
                wordBreak='break-all'
                fontWeight='normal'
                color='gray.500'
                noOfLines={1}
              >
                {subtitle}
              </Text>
            </HStack>
          </VStack>

          {/* to make a call while button click
          1- get other user id using function getOtherUserId(callerId, currentUserId)
          2- then pass the other user id in the component as a props
          3- pass this id to useNewCall function as an argument and also return the icon.
        */}

          <PersonalVoiceCallButton otherUserId={otherUserId ?? 0} />
          <Center>
            {hasMorePersonalCalls &&
              (isVisible ? (
                <IoIosArrowUp size='20' />
              ) : (
                <IoIosArrowDown size='20' />
              ))}
          </Center>
        </ListItem>
        {isVisible && otherUserId && (
          <CallHistory otherUserId={otherUserId} isVisible={isVisible} />
        )}
      </Box>
    );
  },
);

export default CallItem;
