import { ALERT_PAGE_ROUTE } from './routeList';

export enum AlertParams {
  alertId = ':alertId',
  messageThreadId = ':messageThreadId',
}

export const buildAlertPageRoute = (
  alertId: number,
  messageThreadId: number,
): string => {
  return ALERT_PAGE_ROUTE.replace(
    AlertParams.alertId,
    alertId.toString(),
  ).replace(AlertParams.messageThreadId, messageThreadId.toString());
};
