import Icon from '@chakra-ui/icon';
import { HStack, Text } from '@chakra-ui/react';
import { MdExitToApp } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { logout } from 'src/apis/auth.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserLocation } from 'src/hoc';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';

import useDialog from '../hooks/useDialog';
import { LOGIN_PAGE_ROUTE } from '../routes/routeList';
import ConfirmationDialog from './ConfirmationDialog';

interface LogoutButtonProps {}

export const LogoutButton: React.FC<LogoutButtonProps> = () => {
  const { translate } = useTranslate();
  const [
    isLogoutConfirmationDialogOpen,
    openLogoutConfirmationDialog,
    closeLogoutConfirmationDialog,
  ] = useDialog();
  const history = useHistory();
  const { showErrorToast } = useToast();

  const { currentUser, queryResult } = useUserDetails();
  const { locationStream } = useUserLocation();
  const { mutate: logoutMutate } = useMutation(logout, {
    onSuccess: () => {
      localStorage.clear();
      closeLogoutConfirmationDialog();
      queryResult.remove();
      history.replace(LOGIN_PAGE_ROUTE);
    },
    onError: showErrorToast,
  });

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <HStack
        width='full'
        fontWeight='normal'
        justifyContent='space-between'
        height='max-content'
        onClick={openLogoutConfirmationDialog}
      >
        <Text>{translate(TranslationKeys.logout)}</Text>
        <Icon as={MdExitToApp} boxSize='6' />
      </HStack>
      <ConfirmationDialog
        onConfirmed={() => logoutMutate({ ...locationStream })}
        title={translate(TranslationKeys.logout)}
        description={translate(TranslationKeys.areYouSureLogout)}
        isOpen={isLogoutConfirmationDialogOpen}
        onClose={closeLogoutConfirmationDialog}
      />
    </>
  );
};
