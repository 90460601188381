import * as yup from 'yup';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { stringCapitalize } from 'src/utils';

export const useUserSchema = () => {
  const { translate } = useTranslate();

  const userSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterFirstName))
      .transform(stringCapitalize),
    lastName: yup
      .string()
      .required(translate(TranslationKeys.pleaseEnterLastName))
      .transform(stringCapitalize),
    address: yup.string().nullable().transform(stringCapitalize),
    city: yup.string().nullable().transform(stringCapitalize),
    state: yup.string().nullable().transform(stringCapitalize),
    postalAreaCode: yup
      .number()
      .typeError(translate(TranslationKeys.pleaseEnterValidPostalCode))
      .nullable(),
    country: yup.string().nullable().transform(stringCapitalize),
    phoneNumber: yup
      .string()
      .nullable()
      .typeError(translate(TranslationKeys.pleaseEnterValidPhoneNumber)),
    gender: yup
      .string()
      .required(translate(TranslationKeys.pleaseSelectGender)),
  });

  return userSchema;
};

export type UserDto = yup.InferType<ReturnType<typeof useUserSchema>>;
