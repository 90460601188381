import { Box, List } from '@chakra-ui/react';
import * as _ from 'lodash';
import { useState } from 'react';

import { AlertView } from 'src/types/alert.types';
import { getDateInLLLFormat } from 'src/utils/data.util';
import { getUserDisplayName } from 'src/utils/user.utils';

import UserAvatar from '../../../components/UserAvatar';
import useAlerts from '../hooks/useAlerts';
import { AlertFilter } from './AlertFilter';
import AlertItem from './AlertItems';

const AllAlertsPanel = () => {
  const [alertView, setAlertView] = useState(AlertView.ALL);
  const { alerts } = useAlerts(alertView);

  return (
    <Box position='relative'>
      <AlertFilter setAlertView={setAlertView} />
      <List paddingInline='0' pt='5'>
        {_.map(
          alerts,
          ({
            id,
            createdAt,
            raisedBy,
            alertMedias,
            textContent,
            resolvedAt,
            alertMessageThread,
          }) => (
            <AlertItem
              textContent={textContent}
              alertMedias={alertMedias}
              key={id}
              alertId={id}
              messageThreadId={alertMessageThread?.MessageThreadId}
              title={getUserDisplayName(raisedBy)}
              subtitle={getDateInLLLFormat(createdAt)}
              avatarComponent={<UserAvatar user={raisedBy} />}
              isResolved={!!resolvedAt}
            />
          ),
        )}
      </List>
    </Box>
  );
};

export default AllAlertsPanel;
