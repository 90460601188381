import { Box, Text } from '@chakra-ui/react';
import * as L from 'leaflet';
import _ from 'lodash';
import { FC, useContext } from 'react';
import { MapContainer } from 'react-leaflet';

import { LeafletMarker, MarkerColors } from 'src/components/Marker';
import {
  DEFAULT_CENTER_LOCATION,
  DEFAULT_USER_LOCATION,
  DEFAULT_ZOOM_FOR_ALERTS_MAP,
  DEFAULT_ZOOM_FOR_LOCAL_STREAM_NOT_AVAILABLE,
  MAX_BOUNDS,
  MAX_ZOOM,
  MIN_ZOOM,
} from 'src/constants/maps.constant';
import { TranslationKeys } from 'src/constants/translation-keys';
import { UserLocationContext } from 'src/hoc/UserLocationContext/user-location.context';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';

import { MapContainerBody } from './map-body';
import { MapEmptyState } from './map-empty-state';

//INFO: Since react leaflet cannot read icon, we are doing this.
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

interface AlertMapSectionProps {
  alert?: Alert;
  allAlerts?: Alert[];
  mapHeight?: string;
}

export const AlertMapSection: FC<AlertMapSectionProps> = ({
  alert,
  allAlerts,
  mapHeight = '30vh',
}) => {
  const { locationStream } = useContext(UserLocationContext);
  const { translate } = useTranslate();

  const getLatitude = () => {
    if (alert?.latitude) {
      return _.toNumber(alert?.latitude);
    }
    if (locationStream?.latitude) {
      return locationStream?.latitude;
    }
    return DEFAULT_CENTER_LOCATION.latitude;
  };

  const getLongitude = () => {
    if (alert?.longitude) {
      return _.toNumber(alert?.longitude);
    }
    if (locationStream?.longitude) {
      return locationStream?.longitude;
    }
    return DEFAULT_CENTER_LOCATION.longitude;
  };

  const userPosition = locationStream
    ? [locationStream?.latitude, locationStream?.longitude]
    : DEFAULT_USER_LOCATION;

  const mapZoom = locationStream
    ? DEFAULT_ZOOM_FOR_ALERTS_MAP
    : DEFAULT_ZOOM_FOR_LOCAL_STREAM_NOT_AVAILABLE;

  return (
    <Box position='relative' w='full'>
      <MapContainer
        style={{
          height: mapHeight,
        }}
        center={new L.LatLng(getLatitude(), getLongitude())}
        zoom={mapZoom}
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        maxBounds={MAX_BOUNDS}
      >
        <MapContainerBody
          alert={alert}
          allAlerts={allAlerts}
          mapZoom={mapZoom}
          mapCenter={new L.LatLng(getLatitude(), getLongitude())}
        />
        {userPosition && locationStream && (
          <LeafletMarker
            key={alert?.id}
            position={userPosition}
            markerColor={MarkerColors.blue}
            markerContent={
              <Text p='3'>
                {translate(TranslationKeys.yourCurrentLocation)}
              </Text>
            }
          />
        )}
      </MapContainer>
      {alert && !(alert?.latitude || alert?.longitude) && (
        <MapEmptyState user={alert.raisedBy} />
      )}
    </Box>
  );
};
