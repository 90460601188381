import { Text } from '@chakra-ui/react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

export const NothingToShow = () => {
  const { translate } = useTranslate();

  return (
    <Text mt='6' textAlign='center'>
      {translate(TranslationKeys.nothingToShow)}
    </Text>
  );
};
