import {
  Avatar,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as _ from 'lodash';

import { CallSession } from 'src/models/CallSession.model';
import { ColorShaderMap, time24Hour } from 'src/utils';
import { getDuration } from '../utils/call.util';

interface GroupCallTableProps {
  sessions: CallSession[];
}

export const GroupCallTable: React.FC<GroupCallTableProps> = ({ sessions }) => {
  const isEndedVisible = useBreakpointValue({ base: false, sm: true });

  return (
    <Table>
      <Thead>
        <Tr>
          <Th p='1'>Member</Th>
          <Th p='1'>Joined</Th>
          {isEndedVisible && <Th p='1'>Ended</Th>}
          <Th p='1'>Duration</Th>
        </Tr>
      </Thead>
      <Tbody>
        {_.map(_.uniqBy(sessions, 'otherUsers.id'), (session: CallSession) => {
          const sessionWithJoinedAt = session.joinedAt;
          const sessionWithDisconnectedAt = session.disconnectedAt;

          return (
            <Tr
              key={session.otherUsers.id}
              borderBottom={'1px solid'}
              borderBottomColor={ColorShaderMap.gray[200]}
              color='gray.500'
              fontSize='sm'
            >
              <Td p='1'>
                <HStack>
                  <Avatar name={session.otherUsers.fullName} size='xs' />
                  <Text textTransform='capitalize'>
                    {session.otherUsers.fullName.split(' ')[0]}
                  </Text>
                </HStack>
              </Td>
              <Td p='1'>
                {sessionWithJoinedAt &&
                  sessionWithDisconnectedAt &&
                  time24Hour(sessionWithJoinedAt)}
              </Td>
              {isEndedVisible && (
                <Td p='1'>
                  {sessionWithDisconnectedAt &&
                    sessionWithJoinedAt &&
                    time24Hour(sessionWithDisconnectedAt)}
                </Td>
              )}
              <Td p='1'>
                {session.joinedAt &&
                  session.disconnectedAt &&
                  getDuration(session.joinedAt, session.disconnectedAt)}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
