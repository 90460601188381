import { useEffect } from 'react';
import { useMutation } from 'react-query';

import { createAlertMessage } from 'src/apis/alerts.api';
import { useUserLocation } from 'src/hoc';
import { MessageType } from 'src/models/Message.model';
import useAlert from 'src/pages/alerts/hooks/useAlert';
import { useASRChatAudio } from 'src/pages/asr-chat/hooks/use-asr-chat-audio';
import useMessages from 'src/pages/chat/hooks/useMessages';
import useMessageWithLimit from 'src/pages/chat/hooks/useMessageWithLimit';

import { useIntParam } from './use-int-params';
import { useExistingAlert } from './useExistingAlert';

export const useAlertMessage = () => {
  const { alertId, messageThreadId: messageThreadIdFromParam } = useIntParam<{
    alertId: string;
    messageThreadId: string;
  }>();

  const { locationStream } = useUserLocation();

  const { alert, refetch } = useAlert(alertId);
  const { liveAlert, liveAlertMedia } = useExistingAlert(alertId);
  const messageThreadId =
    alert?.alertMessageThread?.MessageThreadId || messageThreadIdFromParam;

  const { message: msgContent, handleMessageChange: setMessage } =
    useMessageWithLimit();
  const { messages, refetch: refetchMessages } = useMessages({
    messageThreadId,
    messageType: MessageType.alertChat,
  });
  const { setTextAudio, textAudio } = useASRChatAudio(messageThreadId);
  const { mutate: createMessageMutate } = useMutation(createAlertMessage);

  const sendAlertMessage = (messageContent: string) => {
    if (!messageContent) return;
    createMessageMutate(
      {
        alertId,
        messageThreadId,
        message: messageContent,
        messageType: MessageType.alertChat,
        ...locationStream,
      },
      {
        onSuccess: () => {
          setMessage('');
          refetchMessages();
        },
      },
    );
  };

  const handleKeyPress: React.KeyboardEventHandler<
    HTMLInputElement
  > = event => {
    if (msgContent.trim().length === 0) {
      return;
    }

    if (event.key === 'Enter') {
      if (msgContent) {
        sendAlertMessage(msgContent);
        setMessage('');
      }
    }
  };

  const handleOnTextInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    setMessage(e.currentTarget.value);
  };

  useEffect(() => {
    if (liveAlert && liveAlert.id !== alertId) {
      window.location.reload();
    }
  }, [alertId, liveAlert]);

  return {
    messages,
    sendAlertMessage,
    handleKeyPress,
    handleOnTextInputChange,
    liveAlert,
    liveAlertMedia,
    refetch,
    setTextAudio,
    textAudio,
    alert,
    msgContent,
    setMessage,
  };
};
