import { MessagePayload } from 'src/types/message.type';

import { axiosInstance } from '../config';
import { GetAllMessagesDto, MessageStatusUpdateDto } from '../dto/messages.dto';
import { AsDto } from '../dto/model-as.dto';
import { Message, MessageType } from '../models/Message.model';
import { getRequestData } from './utils/get-request-data.util';
import { getMessagesUrl } from './utils/message.util';

export const getMessages = async (
  messageThreadId: number,
): Promise<Message[]> => {
  const url = getMessagesUrl(messageThreadId);

  const messages = await getRequestData<GetAllMessagesDto>(
    axiosInstance.get(url),
  );
  return messages.map<Message>(message => ({
    ...message,
    messageAt: new Date(message.messageAt),
    createdAt: new Date(message.createdAt),
  }));
};

export const getASRChatMessages = async (
  messageThreadId: number,
  isLiveChat: boolean,
): Promise<Message[]> => {
  const url = isLiveChat
    ? `${getMessagesUrl(messageThreadId)}/asr-chat/live`
    : `${getMessagesUrl(messageThreadId)}/asr-chat/history`;

  const messages = await getRequestData<GetAllMessagesDto>(
    axiosInstance.get(url),
  );
  return messages.map<Message>(message => ({
    ...message,
    messageAt: new Date(message.messageAt),
    createdAt: new Date(message.createdAt),
  }));
};

export const getAlertChatMessages = async (
  messageThreadId: number,
): Promise<Message[]> => {
  const url = `${getMessagesUrl(messageThreadId)}/alert`;
  const messages = await getRequestData<GetAllMessagesDto>(
    axiosInstance.get(url),
  );
  return messages.map<Message>(message => ({
    ...message,
    messageAt: new Date(message.messageAt),
    createdAt: new Date(message.createdAt),
  }));
};

export const createTextMessage = async (messagePayload: MessagePayload) => {
  const url = getMessagesUrl(messagePayload.messageThreadId);

  const data = {
    ...messagePayload,
    messageAt: new Date().toISOString(),
  };

  return getRequestData<Message>(axiosInstance.post(url, data));
};

export const updateMessageStatus = async (
  messageThreadId: number,
  messageId: number,
  updatedStatus: MessageStatusUpdateDto['status'],
): Promise<Message> => {
  const url = `${getMessagesUrl(messageThreadId)}/${messageId}/status`;
  const data: MessageStatusUpdateDto = {
    status: updatedStatus,
  };

  const updatedMessage = await getRequestData<AsDto<Message>>(
    axiosInstance.patch(url, data),
  );

  return {
    ...updatedMessage,
    messageAt: new Date(updatedMessage.messageAt),
    createdAt: new Date(updatedMessage.createdAt),
    updatedAt: new Date(updatedMessage.updatedAt),
  };
};

export const addTextToSpeechEnabledState = async (
  messageThreadId: number,
  isTextToSpeechEnable: boolean | undefined,
) => {
  const url = getMessagesUrl(messageThreadId);
  return getRequestData(
    axiosInstance.post(`${url}/text-to-speech-state`, { isTextToSpeechEnable }),
  );
};

export const postTextMessage = async (payload: {
  messageThreadId: number;
  text: string;
  messageType: MessageType;
}) => {
  const url = getMessagesUrl(payload.messageThreadId);
  return getRequestData(axiosInstance.post(`${url}/text-to-speech`, payload));
};
