import { Center, Flex } from '@chakra-ui/react';
import _ from 'lodash';
import { FC } from 'react';

import { FOOTER_HEIGHT } from 'src/constants';
import { NavigationLinks } from 'src/pages/home/constants/navigation-link.constant';
import { maxWidth } from '../../constants/max-width.constant';

import { FooterNavigationButton } from './components/FooterNavigationButton';

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  return (
    <Center
      position='fixed'
      w='full'
      h={FOOTER_HEIGHT}
      bottom='0'
      zIndex='modal'
    >
      <Flex
        maxW={maxWidth}
        w='full'
        h='full'
        justifyContent='space-around'
        alignItems='center'
        bg='gray.50'
        borderTop='1px solid'
        borderColor='gray.200'
        boxShadow='rgb(0 0 0 / 10%) 0px -10px 15px 3px, rgb(0 0 0 / 5%) 0px -4px 6px 2px'
      >
        {_.map(
          NavigationLinks,
          link =>
            link.path && (
              <FooterNavigationButton
                key={link.label}
                {...link}
                path={link.path}
              />
            ),
        )}
      </Flex>
    </Center>
  );
};
