import { MutableRefObject, memo } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Flex,
  HStack,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';

import { GroupCalls } from 'src/models/GroupCall';
import { getCallerName } from 'src/utils/get-call-details';
import { getDateInLLLFormat } from 'src/utils/data.util';

import { GroupCallIcon } from './GroupCallIcon';
import RandomGroupCallButton from './RandomGroupCallButton';
import GroupVoiceCallButton from './GroupVoiceCallButton';
import { CallAvatar } from './CallAvatar';
import { GroupCallTable } from './GroupCallTable';

interface GroupCallItemProps {
  call: GroupCalls;
  observerRef: MutableRefObject<HTMLDivElement | null> | null;
}

const GroupCallItem: React.FC<GroupCallItemProps> = memo(
  ({ call, observerRef }) => {
    const { messageGroup, session, MessageGroupId, createdAt, callStatus } =
      call;

    return (
      <AccordionItem
        key={MessageGroupId}
        ref={observerRef}
        _focus={{}}
        _active={{}}
        _selected={{}}
        _hover={{}}
        _expanded={{ border: 'none', bg: 'transparent' }}
      >
        <ListItem
          py='2'
          px='0'
          as={AccordionButton}
          _focus={{}}
          _active={{}}
          _selected={{}}
          _hover={{}}
          _expanded={{ border: 'none', bg: 'transparent' }}
        >
          <Flex pr='4'>
            <CallAvatar sessions={session} messageGroup={messageGroup} />
          </Flex>
          <VStack align='start' spacing='0' w='full'>
            <Text
              textAlign='start'
              fontWeight='bold'
              fontSize='lg'
              noOfLines={1}
            >
              {getCallerName(messageGroup, session)}
            </Text>
            <HStack w='full'>
              <Text fontWeight='bold'>
                <GroupCallIcon sessions={session} callStatus={callStatus} />
              </Text>
              <Text
                noOfLines={1}
                fontWeight='normal'
                color='gray.500'
                whiteSpace='nowrap'
              >
                {getDateInLLLFormat(createdAt)}
              </Text>
            </HStack>
          </VStack>
          <Center px='0'>
            {MessageGroupId ? (
              <GroupVoiceCallButton
                groupMessageThreadId={messageGroup.MessageThreadId}
                groupId={MessageGroupId}
              />
            ) : (
              <RandomGroupCallButton sessions={session} />
            )}
          </Center>
          <AccordionIcon boxSize='6' ml={{ base: '0', sm: '2' }} />
        </ListItem>
        <AccordionPanel px={0} pb={4}>
          <GroupCallTable sessions={session} />
        </AccordionPanel>
      </AccordionItem>
    );
  },
);
export default GroupCallItem;
