import { Box, Center } from '@chakra-ui/layout';
import React from 'react';

import { maxWidth } from 'src/constants/max-width.constant';
import { CHAT_FOOTER_HEIGHT } from 'src/constants/message.constant';

import ChatInputPanel, { ChatInputPanelProps } from './ChatInputPanel';

interface ChatFooterProps {
  chatInputPanelProps: ChatInputPanelProps;
}

const ChatFooter = React.forwardRef<HTMLDivElement, ChatFooterProps>(
  ({ chatInputPanelProps }, ref) => {
    return (
      <Center height={CHAT_FOOTER_HEIGHT} ref={ref} w='full' zIndex='10'>
        <Box maxW={maxWidth} position='relative' w='full'>
          <ChatInputPanel bg='white' {...chatInputPanelProps} />
        </Box>
      </Center>
    );
  },
);

export default ChatFooter;
