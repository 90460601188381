import { useQuery } from 'react-query';

import { getAlertMembers } from 'src/apis/alert-members.api';
import { AlertGroupMembers } from 'src/models/alert-group-member';

const kAlertMembersQueryKey = 'alertMembers';

export const useAlertMembers = () => {
  const { data: allAlertMembers, ...queryMetadata } = useQuery<
    AlertGroupMembers[]
  >(kAlertMembersQueryKey, getAlertMembers);

  return { allAlertMembers, queryMetadata };
};
