import {
  Button,
  ButtonGroup,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { TranslationKeys } from 'src/constants/translation-keys';

import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import { getPageContentRendererId } from 'src/pages/blogs/blog/components/page-content/utils/getPageContentRendererId.util';
import usePageParams from 'src/pages/blogs/blog/hooks/usePageParams';

import usePageMembers from '../hooks/usePageMembers';
import { Editor } from './components/editor/Editor';
import usePageContentForEdit from './hooks/usePageContentForEdit';
import usePublishPageContent from './hooks/usePublishPageContent';

interface EditPageContentTabProps {}

export const EditPageContentTab: React.FC<EditPageContentTabProps> = () => {
  const { translate } = useTranslate();
  const { id, id: pageId } = usePageParams();
  const {
    newContent,
    setNewContent,
    isContentLoading,
    refetchPageContent,
    previousContent,
  } = usePageContentForEdit();

  const { pageMembers } = usePageMembers({
    pageId,
  });

  const { currentUser } = useUserDetails();
  const ButtonSize = useBreakpointValue({ base: 'sm', sm: 'md' });

  const currentUserMemberRecord = useMemo(
    () => _.find(pageMembers, member => member.UserId === currentUser?.id),
    [pageMembers, currentUser],
  );

  const { handlePublishPageContent, updatePageContentMutation } =
    usePublishPageContent({ id, refetchPageContent });

  const isContentChanged = !_.isEqual(newContent, previousContent);

  const handlePublishChanges = () => {
    if (isContentChanged && newContent) {
      handlePublishPageContent(newContent);
    }
  };

  const [isReadonly, setIsReadonly] = useState(false);

  const toggleReadonly = () => setIsReadonly(prev => !prev);

  const isPublishingChanges = updatePageContentMutation.isLoading;

  if (isContentLoading)
    return (
      <Flex justify='center' align='center'>
        {translate(TranslationKeys.loading)}
      </Flex>
    );

  return (
    <>
      {currentUserMemberRecord?.canModerateUpdates && (
        <Editor
          isReadonly={isReadonly}
          editorKeyId={getPageContentRendererId(id)}
          w='full'
          value={newContent}
          onChange={setNewContent}
        />
      )}

      <Flex justify='flex-end'>
        <ButtonGroup
          isDisabled={!isPublishingChanges && !isContentChanged}
          colorScheme='blue'
          alignSelf='end'
          size={ButtonSize}
        >
          <Button
            onClick={toggleReadonly}
            variant='outline'
            fontSize={{ base: 'xs', sm: 'sm' }}
          >
            {isReadonly
              ? translate(TranslationKeys.continueEditing)
              : translate(TranslationKeys.previewChanges)}
          </Button>
          {currentUserMemberRecord?.canModerateUpdates && (
            <Button
              onClick={handlePublishChanges}
              isLoading={isPublishingChanges}
              fontSize={{ base: 'xs', sm: 'sm' }}
            >
              {translate(TranslationKeys.publishChanges)}
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </>
  );
};
